import { useEffect, useState } from "react"
import Axios from "axios"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare, faEye, faRefresh, faTerminal } from "@fortawesome/free-solid-svg-icons"
import { Button, Label, Select, Pagination, Spinner, Datepicker } from "flowbite-react"
import Loader from "../../componentes/Loader"
import { obtenerFechaHoy } from "../../recursos/funciones"
import { apiSyslog } from "../../recursos/configuracion"

function Syslog() {

    // Estado para conocer si se está haciendo una petición
    const [carga, setCarga] = useState(false)

    // Valores para las options dentro de los selects
    const [valoresParaFiltros, setValoresParaFiltros] = useState()

    // Estado para la lista de errores
    const [errores, setErrores] = useState()

    // Para obtener la cantidad de datos disponibles relacionados a la paginación (vendrá del backend)
    const [valoresParaPaginacion, setValoresParaPaginacion] = useState()

    const [paginaActual, setPaginaActual] = useState(1);

    // Para almacenar los estados de los filtros y enviarlo dentro de cargarErrores  
    const [filtros, setFiltros] = useState(
        {
            ambiente: "Sin Filtro",

            usuario: "Sin Filtro",

            programa: "Sin Filtro",

            lado: "Sin Filtro",

            archivo: "Sin Filtro",

            funcion: "Sin Filtro",

            tipo: "Sin Filtro",

            fechaInicio: "",

            fechaFin: obtenerFechaHoy()
        }
    )

    // Establece el nuevo valor de la página y vuelve a cargar los errores correspondientes a esa página
    async function manejadorCambioPagina(pagina) {
        try {
            setPaginaActual(pagina)
            await cargarErrores(pagina)
        } catch (error) {
            console.log(error);
        }
    }

    /* 
       Crea un objeto con los valor seleccionados si no es "Sin Filtro". 
       Si es, lo establece como undefined (porque así lo requiere el backend)
    */
    function obtenerFiltrosParaBody(filtros) {
        let nuevoObjeto = {};
        for (let key in filtros) {
            if (filtros.hasOwnProperty(key))
                nuevoObjeto[key] = filtros[key] !== "Sin Filtro" ? filtros[key] : undefined
        }

        return nuevoObjeto
    }


    // Se ejecuta cada vez que se seleccione un nuevo filtro
    async function manejadorCambioFiltro(e, nombreFiltro) {
        // console.log("e.target.value", e.target.value);
        setFiltros(previo => ({ ...previo, [nombreFiltro]: e.target.value }))
    }

    // Trae valores para las options del select
    async function cargarValoresParaFiltros() {
        try {
            setCarga(true)
            const resultado = await Axios.get(`${apiSyslog}/listas`)
            setValoresParaFiltros(resultado.data.objetoJson)
        } catch (error) {
            console.log(error);
        }

        setCarga(false)
    }

    // Trae lista de errores
    async function cargarErrores(valor = 1) {
        try {
            setCarga(true)
            let resultado = await Axios.put(`${apiSyslog}/registros?pagina=${valor}&intervalo=10`, obtenerFiltrosParaBody(filtros))
            setErrores(resultado.data.arrayJson)
            setValoresParaPaginacion(resultado.data.objetoJson.paginado)
        } catch (error) {
            console.log(error);
        }
        setCarga(false)
    }


    // Se ejecuta solamente después del primer renderizado
    useEffect(() => {
        cargarValoresParaFiltros()
        cargarErrores()
    }, [])

    // Se ejecuta posterior a cada nueva selección
    useEffect(() => {
        cargarErrores(paginaActual, filtros)
    }, [filtros])

    // Cada 15 segundos llama a la función cargarErrores
    // ¡OJO SI NO ESCALA!
    useEffect(() => {
        console.log(filtros);
        const interval = setInterval(() => {
            cargarErrores(paginaActual, filtros);
        }, 15000);

        return () => clearInterval(interval);
    }, [filtros])

    return (
        <div className="mb-8"><h2 className="sub-titulo">
            <span className="mr-2">
                {carga ? <Spinner size="md" /> : <FontAwesomeIcon icon={faTerminal} />}
            </span> Syslog</h2>
            <div className="mt-4 grid gap-3 lg:grid-cols-3 lg:gap-4">
                {/* Estructura de cada Select */}
                <div>
                    <div>
                        <Label htmlFor="ambiente" value="Ambiente" />
                    </div>
                    <Select value={filtros.ambiente} onChange={(e) => manejadorCambioFiltro(e, "ambiente")} id="ambiente">
                        <option value="Sin Filtro">Sin Filtro</option>
                        {valoresParaFiltros?.ambiente.map((elemento, llave) => {
                            return <option key={llave} value={elemento.nombre} > {elemento.nombre} </option>
                        })}
                    </Select>
                </div>

                <div>
                    <div>
                        <Label htmlFor="usuario" value="Usuario" />
                    </div>
                    <Select onChange={(e) => manejadorCambioFiltro(e, "usuario")} id="usuario">
                        <option value="Sin Filtro" >Sin Filtro</option>
                        {valoresParaFiltros?.usuario.map((elemento, llave) => {
                            return <option key={llave} value={elemento.nombre} > {elemento.nombre} </option>
                        })}
                    </Select>
                </div>

                <div>
                    <div>
                        <Label htmlFor="programa" value="Programa" />
                    </div>
                    <Select onChange={(e) => manejadorCambioFiltro(e, "programa")} id="programa">
                        <option value="Sin Filtro">Sin Filtro</option>
                        {valoresParaFiltros?.programa.map((elemento, llave) => {
                            return <option key={llave} value={elemento.nombre} > {elemento.nombre} </option>
                        })}
                    </Select>
                </div>

                <div>
                    <div>
                        <Label htmlFor="lado" value="Lado" />
                    </div>
                    <Select value={filtros.lado} onChange={(e) => manejadorCambioFiltro(e, "lado")} id="lado">
                        <option value="Sin Filtro" >Sin Filtro</option>
                        {valoresParaFiltros?.lado.map((elemento, llave) => {
                            return <option key={llave} value={elemento.nombre} > {elemento.nombre} </option>
                        })}
                    </Select>
                </div>

                <div>
                    <div>
                        <Label htmlFor="archivo" value="Archivo" />
                    </div>
                    <Select onChange={(e) => manejadorCambioFiltro(e, "archivo")} id="Archivo">
                        <option value="Sin Filtro" >Sin Filtro</option>
                        {valoresParaFiltros?.archivo.map((elemento, llave) => {
                            return <option key={llave} value={elemento.nombre} > {elemento.nombre} </option>
                        })}
                    </Select>
                </div>

                <div>
                    <div>
                        <Label htmlFor="funcion" value="Función" />
                    </div>
                    <Select id="funcion" onChange={(e) => manejadorCambioFiltro(e, "funcion")}>
                        <option value="Sin Filtro">Sin Filtro</option>
                        {valoresParaFiltros?.funcion.map((elemento, llave) => {
                            return <option key={llave} value={elemento.nombre} > {elemento.nombre} </option>
                        })}
                    </Select>
                </div>


                <div>
                    <div>
                        <Label htmlFor="tipo" value="Tipo" />
                    </div>
                    <Select id="tipo" onChange={(e) => manejadorCambioFiltro(e, "tipo")}>
                        <option value="Sin Filtro">Sin Filtro</option>
                        <option value="0" >0</option>
                        <option value="1" >1</option>

                    </Select>
                </div>

                <div>
                    <div>
                        <label htmlFor="fechaInicio">Fecha Inicio</label>
                    </div>
                    <input
                        className="w-full rounded-md border-none bg-gray-700 border-2 focus:ring-verde"
                        value={filtros.fechaInicio} onChange={(e) => manejadorCambioFiltro(e, "fechaInicio")}
                        type="date"
                        id="fechaInicio"
                    />
                </div>


                <div>
                    <div>
                        <label htmlFor="fechaFin" value="Fecha Fin">Fecha Fin</label>
                    </div>
                    <input
                        className="w-full rounded-md border-none bg-gray-700 focus:ring-verde"
                        value={filtros.fechaFin} onChange={(e) => manejadorCambioFiltro(e, "fechaFin")}
                        type="date"
                        id="fechaFin"
                        max={obtenerFechaHoy()}
                    />
                </div>


            </div>

            <div className="mt-8 overflow-x-auto flex flex-col lg:flex-row lg:justify-between lg:items-center">
                <div>
                    <Button className="bg-gray-900 transition-colors" onClick={() => cargarErrores(paginaActual, filtros)} >
                        <FontAwesomeIcon icon={faRefresh} />
                        <span className="ml-1">Actualizar</span>
                    </Button>
                </div>
                {valoresParaPaginacion && <div className="flex gap-2 items-center">
                    <span className="text-gray-900 text-[12px] mt-1 dark:text-white"> Página {paginaActual} / {valoresParaPaginacion.fin} </span>
                    <Pagination 
                        currentPage={paginaActual} 
                        totalPages={valoresParaPaginacion.fin}
                        onPageChange={manejadorCambioPagina}
                        showIcons
                        previousLabel=""
                        nextLabel=""
                    />
                </div>}
            </div>

            {!errores ? <Loader /> : <div className="mt-8 overflow-x-auto" id="tabla-syslog">
                <table className="border border-gray-900">
                    <thead>
                        <tr>
                            <th>Acción</th>
                            <th>Caso</th>
                            <th>Ambiente</th>
                            <th>Usuario</th>
                            <th>Programa</th>
                            <th>Lado</th>
                            <th>Archivo</th>
                            <th>Función</th>
                            <th>Descripción</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody className="mt-2">
                        {
                            errores?.map((elemento, llave) => {
                                return <tr className="mb-4" key={llave}>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800 `} >
                                        <div className="flex justify-center gap-2 items-center">
                                            <div>{<FontAwesomeIcon icon={faEye} />} </div>
                                            <div>{<FontAwesomeIcon icon={faArrowUpRightFromSquare} />} </div>
                                        </div>
                                    </td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} > {elemento.caso} </td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} > {elemento.ambiente} </td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} >Usuario</td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} > {elemento.programa} </td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} >{elemento.lado}</td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} >{elemento.archivo}</td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} > {elemento.funcion} </td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} >{elemento.descripcion}</td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} >{moment(elemento.tiempo).date()}</td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} > {moment(elemento.tiempo).hour()} </td>
                                    <td className={`${elemento.tipo === 1 ? "text-red-500" : "text-green-500"} dark:bg-gray-800`} >{elemento.tipo === 1 ? "Error" : "Info"}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export default Syslog