import { useEffect, useState } from "react";


import { useNavigate, useLocation, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Swal from "sweetalert2";

import { useCuentaContexto, useSetCuentaContexto } from "../proveedor/cuenta";

import {
  obtenerCuenta,
  obtenerInterfaz,
  reportar,
} from "../recursos/funciones";

import { apiAutenticacion } from "../recursos/configuracion";

import Axios from "axios";
import { Avatar, Dropdown, ToggleSwitch } from "flowbite-react";
import { faArrowLeft, faArrowRight, faBars, faChartSimple, faCoins, faComputer, faHeadset, faHome, faMagnifyingGlassDollar, faMoneyBill, faMoneyBillTransfer, faMoon, faRightFromBracket, faRoadBarrier, faScrewdriverWrench, faShoppingCart, faSun, faTag, faUsers, faX } from "@fortawesome/free-solid-svg-icons";

const valorInicialPopups = {
  inicio: false,
  riesgos: false,
  cobranzas: false,
  tesoreria: false,
  rrhh: false,
  marketing: false,
  soporteTec: false,
  compras: false,
  ventas: false,
  informatica: false
}

function Navegacion() {
  // Consume el contexto cuentaContexto
  const cuenta = useCuentaContexto();

  // Consume el contexto setCuentaContexto
  const establecerCuenta = useSetCuentaContexto();

  const localizacion = useLocation();
  const navegar = useNavigate();

  // Estado para manejar si el navbar está abierto o no. En desktop está cerrado cuando está collapsado
  const [navAbierto, setNavAbierto] = useState(obtenerNavEstado)
  const [popups, setPopups] = useState(valorInicialPopups)

  function obtenerNavEstado() {
    // En móvil por defecto está cerrado. En desktop por defecto está abierto
    if (window.innerWidth < 500) return false
    return true
  }

  function manejadorPopups(e, id) {
    console.log(id);
  }

  function manejadorNavAbierto() {
    // Cierre el navbar en versión móvil cuando se hace click en uno de los links
    if (window.innerWidth < 500) {
      setNavAbierto(false)
    }
  }

  // Cada 30 segundos llama a verificarToken con el tipo
  useEffect(() => {
    setInterval(() => {
      verificarToken(1);
    }, 30000);
  }, []);

  useEffect(() => {
    //console.log(cuenta);
  }, [cuenta]);

  useEffect(() => {
    //console.log(localizacion);
    verificarToken(0);
  }, [localizacion]);

  async function verificarToken(tipo) {
    // Obtiene el token del sessionStorage
    let token = sessionStorage.getItem("autorizacion");

    if (token === null || token === "") {
      /* Si token es null o un string vacío y el nombre de la ruta actual es diferente a "/" 
    redirigimos a la página Acceso */

      if (localizacion.pathname !== "/") {
        navegar("/");
      }
    } else {
      if (await validarToken(token)) {
        if (tipo === 0) {
          if (localizacion.pathname === "/") {
            navegar("/inicio");
          }
        }
      } else {
        navegar("/inicio");
      }
    }
  }

  // Si valida el token llamando a la api, y está ok devuelve true, si no devuelve false
  async function validarToken(token) {
    let envio = false;
    try {
      let respuesta = await Axios.post(
        `${apiAutenticacion}/comprobar`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (respuesta.data.codigo === 1) {
        envio = true;
        // obtenerCuenta recibe el token y devuelve los datos necesarios
        const cuentaVerificada = obtenerCuenta(token);
        establecerCuenta({
          token: token,
          nombre: cuentaVerificada.nombre,
          apellido: cuentaVerificada.apellido,
          rol: cuentaVerificada.rol.nombre,
          interfaces: cuentaVerificada.rol.interfaz,
        });
      } else {
        revocarToken();
        envio = false;
      }
      return envio;
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        "Ecommerce Admin",
        "Frontend",
        "BarraSuperior.jsx",
        "validarToken",
        `${error.toString()}`,
        {
          error,
        },
        1
      );
    }
  }

  // Invalida el token y setea el sessionStorage con valores vacíos
  async function revocarToken() {
    try {
      let token = sessionStorage.getItem("autorizacion");
      let respuesta = await Axios.delete(`${apiAutenticacion}/cerrar`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (respuesta.data.codigo === 0) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: respuesta.data.descripcion,
        });
        navegar("/");
        sessionStorage.removeItem("autorizacion");
        localStorage.removeItem("ruta");
        establecerCuenta({
          token: "",
          nombre: "",
          apellido: "",
          rol: "",
          interfaces: [],
        });
      }
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        "Ecommerce Admin",
        "Frontend",
        "BarraSuperior.jsx",
        "revocarToken",
        `${error.toString()}`,
        {
          error,
        },
        1
      );
    }
  }


  return (
    <>
      <nav id="nav" className={`${navAbierto ? "lg:w-[13%] lg:min-w-[13%] abrir" : "w-0 lg:w-[5%] lg:min-w-[5%]"} bg-gray-900 lg:sticky 
      z-10 top-0 bottom-0 min-h-screen lg:border-r-2 border-gray-900 dark:border-white`}>

        <div className="absolute left-1 top-1 z-50 dark:text-white md:hidden">
          <button onClick={() => setNavAbierto(!navAbierto)} > {navAbierto ? <FontAwesomeIcon size="xl" icon={faX} /> : <FontAwesomeIcon size="xl" icon={faBars} />} </button>
        </div>

        <div className={`${!navAbierto && "hidden lg:px-2 lg:items-center"} mt-8 ml-8 lg:mt-0 lg:ml-0 min-h-screen lg:px-8 
        lg:py-12 lg:flex flex-col justify-between text-white`}>

          {/* Botón para abrir/cerrar navbar */}
          <div className="absolute top-2 -right-4 z-10">
            <div className="hidden lg:block">
              <button className="w-8 h-8 bg-white text-azulDark border-2 border-azulDark dark:border-none rounded-full"
                onClick={() => setNavAbierto(!navAbierto)}>
                {navAbierto ? <FontAwesomeIcon icon={faArrowLeft} /> : <FontAwesomeIcon icon={faArrowRight}
                />}
              </button>
            </div>
          </div>

          <div className="my-4 lg:my-0">
            {
              navAbierto ? <NavLink className="text-lg" to="/inicio">
                Central Shop
                <span className="ml-1">
                  <FontAwesomeIcon icon={faTag} />
                </span>
              </NavLink> : <NavLink className="text-lg" to="/inicio">
                CS
                <span className="ml-1">
                  <FontAwesomeIcon icon={faTag} />
                </span>
              </NavLink>
            }
          </div>

          <div className="flex flex-col gap-5">
            {obtenerInterfaz(cuenta.interfaces, "inicio") && (
              <NavLink onClick={manejadorNavAbierto} className="flex gap-2 items-center" to="/inicio">
                <FontAwesomeIcon size="lg" icon={faHome} />
                {navAbierto && <button onClick={(e) => manejadorPopups(e, "inicio")}>Inicio</button>}
              </NavLink>
            )}
            {obtenerInterfaz(cuenta.interfaces, "referencias") && (
              <details className="relative">
                <summary className="list-none">
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon size="lg" icon={faRoadBarrier} />
                    {navAbierto && <span>Riesgos <span className="ml-6">+</span> </span>}
                  </div>
                  {/* <span className="ml-1">+</span> */}
                </summary>
                {navAbierto && <div className="pl-4">
                  <NavLink onClick={manejadorNavAbierto} to="referencias/consulta">
                    Consulta para referencias
                  </NavLink>
                </div>}

                {/* <div id="popup" className=" bg-white hover:visible hover:w-16 absolute z-10 top-0">
                  <div>
                    <h5 className="text-red-500">hola</h5>
                  </div>
                </div> */}
              </details>
            )}
            {obtenerInterfaz(cuenta.interfaces, 'cobranzas') && <NavLink onClick={manejadorNavAbierto} className="flex gap-2 items-center" to="/cobranzas">
              <FontAwesomeIcon size="lg" icon={faMoneyBillTransfer} />
              {navAbierto && <span>Cobranzas</span>}
            </NavLink>}
            {obtenerInterfaz(cuenta.interfaces, 'tesoreria') && <NavLink onClick={manejadorNavAbierto} className="flex gap-2 items-center" to="/tesoreria">
              <FontAwesomeIcon size="lg" icon={faCoins} />
              {navAbierto && <span>Tesorería</span>}
            </NavLink>}
            {obtenerInterfaz(cuenta.interfaces, "recursos_humanos") && (
              <details>
                <summary className="list-none">
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon size="lg" icon={faUsers} />
                    {navAbierto && <span>RR.HH. <span className="ml-6">+</span> </span>}
                  </div></summary>
                {navAbierto && <div className="pl-4">
                  <NavLink onClick={manejadorNavAbierto} to="recursos_humanos/salarios">
                    Salarios
                  </NavLink>
                </div>}

              </details>
            )}
            {obtenerInterfaz(cuenta.interfaces, 'marketing') &&
              <NavLink onClick={manejadorNavAbierto} className="flex gap-2 items-center" to="/marketing">
                <FontAwesomeIcon size="lg" icon={faChartSimple} />
                {navAbierto && <span>Marketing</span>}

              </NavLink>}
            {obtenerInterfaz(cuenta.interfaces, 'soporte_tecnico') && <NavLink onClick={manejadorNavAbierto} to="/soporte_tecnico">
              <div className="flex gap-2 items-center">
                <FontAwesomeIcon size="lg" icon={faScrewdriverWrench} />
                {navAbierto && <span>Soporte Téc.</span>}
              </div>
            </NavLink>}
            {obtenerInterfaz(cuenta.interfaces, 'compras') && <NavLink onClick={manejadorNavAbierto} to="/compras">
              <div className="flex gap-2 items-center" >
                <FontAwesomeIcon size="lg" icon={faShoppingCart} />
                {navAbierto && <span>Compras</span>}
              </div>
            </NavLink>}
            {obtenerInterfaz(cuenta.interfaces, 'ventas') && (
              <details>
                <summary className="list-none">
                  <div className="flex gap-2 items-center">
                    <FontAwesomeIcon size="lg" icon={faHeadset} />
                    {navAbierto && <span> Ventas <span className="ml-6">+</span> </span>}
                  </div> </summary>
                {navAbierto && <div className="pl-4 flex flex-col gap-1">
                  <NavLink onClick={manejadorNavAbierto} to="ventas/solicitudes">
                    Solicitudes
                  </NavLink>

                  <NavLink onClick={manejadorNavAbierto} to="ventas/nueva-solicitud">
                    Nueva Solicitud
                  </NavLink>
                </div>}
              </details>
            )}

            {obtenerInterfaz(cuenta.interfaces, 'informatica') && (
              <details>
                <summary className="list-none">
                  <div className="flex gap-2 items-center" >
                    <FontAwesomeIcon size="lg" icon={faComputer} />
                    {navAbierto && <span>
                      Informática
                      <span className="ml-6">+</span>
                    </span>}

                  </div>
                </summary>
                {navAbierto && <div className="pl-4 flex flex-col gap-1">
                  <NavLink onClick={manejadorNavAbierto} to="informatica/procesos">
                    Procesos
                  </NavLink>

                  <NavLink onClick={manejadorNavAbierto} to="informatica/syslog">
                    Syslog
                  </NavLink>

                  <NavLink onClick={manejadorNavAbierto} to="informatica/personas">
                    Personas
                  </NavLink>
                </div>}
              </details>
            )}
          </div>

          <div>
            {navAbierto ? <div className="flex items-center gap-1">
              <NavLink to="/" onClick={revocarToken}>Cerrar sesión</NavLink>
              <FontAwesomeIcon icon={faRightFromBracket} />
            </div> : <div className="flex items-center gap-1">
              <FontAwesomeIcon icon={faRightFromBracket} />
            </div>}

          </div>

        </div>
      </nav>
    </>
  );
}

export default Navegacion;
