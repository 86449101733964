

function Desarrollo() {

    return (
        <main className="contenedor bg-gray-800 text-white">
            <h1>En Desarrollo</h1>
        </main>
    );
}

export default Desarrollo;