import {
    createContext,
    useState,
    useContext
} from 'react';

// Contexto que manejará la cuenta de manera global
const cuentaContexto = createContext();

// Contexto que se encargará de establecer el valor de la cuenta
const setCuentaContexto = createContext();

// Custom hook para obtener el valor de cuentaContexto
export function useCuentaContexto() {
    return useContext(cuentaContexto);
}


// Custom hook para obtener la función que asignará el nuevo valor de la cuenta
export function useSetCuentaContexto() {
    return useContext(setCuentaContexto);
}                                                                                                                                         

export function CuentaProveedor(props) {

    // Valor inicial de cuenta (y esquema que tendría que tener) y la función para actualizarlo
    const [cuenta, setCuenta] = useState(
        {
            nombre: '',
            apellido: '',
            rol: '',
            token: '',
            interfaces: []
        }
    );

    // Función que se pasará como valor al proveedor del contexto setCuentaContexto
    function establecerCuenta(usuarioActual) {
        setCuenta(usuarioActual);
    }

    return (
        <cuentaContexto.Provider value={cuenta}>
            <setCuentaContexto.Provider value={establecerCuenta}>
                {props.children}
            </setCuentaContexto.Provider>
        </cuentaContexto.Provider>
    );
}