const apiCloudsystem = process.env.REACT_APP_API_CLOUDSYSTEM
const apiAutenticacion = process.env.REACT_APP_API_AUTENTICACION
const apiSyslog = process.env.REACT_APP_API_SYSLOG
const apiRiesgos = process.env.REACT_APP_API_RIESGOS
const apiProcesos = process.env.REACT_APP_API_PROCESOS
const apiAmbiente = process.env.REACT_APP_API_AMBIENTE

module.exports = {
    apiCloudsystem,
    apiAutenticacion,
    apiSyslog,
    apiRiesgos,
    apiProcesos,
    apiAmbiente
}