import { createContext, useContext, useEffect, useState } from "react";

// contexto para el booleano modo claro-oscuro
const modoOscuroContexto = createContext()

// contexto para cambiar valor del modo oscuro
const setModoOscuroContexto = createContext()

export function useModoOscuro() {
    return useContext(modoOscuroContexto)
}

export function useSetModoOscuro() {
    return useContext(setModoOscuroContexto)
}


// Proveedor que pasará los datos de los contextos
export default function ModoOscuroProveedor({ children }) {
    const [modoOscuro, setModoOscuro] = useState(obtenerModo())

    function obtenerModo() {
        if (window.matchMedia('(prefers-color-scheme:dark)').matches || localStorage.getItem("theme") === "dark") return true
        else return false
    }

    useEffect( () => {
        console.log("modoOscuro en proveedor", modoOscuro);
    })

    return (
        <modoOscuroContexto.Provider value={modoOscuro}>
            <setModoOscuroContexto.Provider value={setModoOscuro}>
                {children}
            </setModoOscuroContexto.Provider>
        </modoOscuroContexto.Provider>
    )
}

