
import { useEffect, useState } from "react"
import { Pagination } from "flowbite-react"
import axios from "axios"
import { apiCloudsystem } from "../../recursos/configuracion"
import Loader from "../../componentes/Loader"
import { NavLink } from "react-router-dom"
import { obtenerFechaDeTiempo, separadorDeMiles } from "../../recursos/funciones"

function Personas() {

  const [carga, setCarga] = useState(true)
  const [personas, setPersonas] = useState([])
  const [paginaActual, setPaginaActual] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState()
 
  const cabeceraPersonas = [
    "CI",
    "Nombres",
    "Apellidos",
    "Email",
    "Fecha Nac.",
    "Razón Social",
    "DV",
    "Activo",
  ]

  async function manejadorCambioPagina(pagina) {
    try {
      setPaginaActual(pagina)
      await cargarPersonas(pagina)
    } catch (error) {
      console.log(error);
    }
  }

  async function cargarPersonas(pagina = 1) {
    try {
      const resultado = await axios.get(`${apiCloudsystem}/personas?pagina=${pagina}&intervalo=${20}`)
      console.log(resultado.data)
      setPersonas(resultado.data.lista)
      setTotalPaginas(resultado.data.objeto.paginas.fin)
    } catch (error) {
      console.log(error.message);
    }

    setCarga(false)

  }

  useEffect(() => {
    cargarPersonas()
  }, [])

  return (
    <div>
      {personas?.length === 0 ? <Loader /> :
        <div className="mb-12">
          <div className="flex justify-between" >
            <h2 className="sub-titulo mt-8">Personas</h2>
            <div className="flex items-center gap-3">
              <span>Página { paginaActual } / { totalPaginas }</span>
              <Pagination
                previousLabel=""
                nextLabel=""
                showIcons
                currentPage={paginaActual}
                totalPages={totalPaginas}
                onPageChange={manejadorCambioPagina}
              />
            </div>
          </div>
          <div className="overflow-x-auto mt-2">
          <table className="mt-4 border-2 border-gray-900 dark:border-gray-900">
            <thead>
              <tr className="bg-gray-900 text-white">
                {
                  cabeceraPersonas.map((elemento, index) => {
                    return <th className="border-r-2 last:border-0 border-gray-700 py-4" key={index} >{elemento}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                personas?.map(persona => {
                  return <tr className="border-b-2 border-gray-900 dark:bg-gray-700" key={persona.id_persona}>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{separadorDeMiles(persona.numero_documento)}</NavLink> </td>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{persona.nombres}</NavLink></td>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{persona.apellidos}</NavLink></td>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{persona.email === "" ? "---" : persona.email}</NavLink> </td>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{obtenerFechaDeTiempo(persona.fecha_nacimiento)}</NavLink></td>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{persona.razon_social === "" ? "---" : persona.razon_social}</NavLink> </td>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{persona.dv}</NavLink> </td>
                    <td className="border-r-2 border-gray-900 py-2" ><NavLink className="inline-block w-full" to={`/informatica/personas/${persona.numero_documento}`}>{persona.activo}</NavLink> </td>
                  </tr>
                })
              }
            </tbody>
          </table>
          </div>
        </div>
      }
    </div>
  )
}

export default Personas