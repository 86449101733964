
function Inicio() {

    return (

        <main className="contenedor w-full flex justify-center items-center text-gray-800 dark:text-white bg-white 
        dark:bg-grisDark">
            <h1 className="text-4xl font-bold text-center lg:text-6xl">¡Bienvenido a Cloud System de Central Shop!</h1>
        </main>

    );
}

export default Inicio;