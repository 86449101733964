

import {
    Outlet
} from 'react-router-dom';

function Recursos_humanos() {

    return (
        <main className="contenedor dark:bg-grisDark">
            <h1 className="titulo text-gray-900 dark:text-white">Recursos Humanos</h1>
            <div>
                <Outlet />
            </div>
        </main>
    );
}

export default Recursos_humanos;