import { Outlet } from "react-router-dom"

function Ventas() {
  return (
    <main className="contenedor dark:bg-grisDark">
        <h1 className="titulo text-gray-900 dark:text-white">Ventas</h1>
        <Outlet />
    </main>
  )
}

export default Ventas