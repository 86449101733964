import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { apiCloudsystem } from "../../recursos/configuracion"
import Loader from "../../componentes/Loader"
import { obtenerFechaDeTiempo } from "../../recursos/funciones"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Label, Modal, TextInput } from "flowbite-react"

function Persona() {

    const { id } = useParams()

    const [carga, setCarga] = useState(true)
    const [persona, setPersona] = useState()
    const [abrirModal, setAbrirModal] = useState(false)

    const cabeceraPersona = [
        { nombre: "CI", id: "numero_documento", valor: persona?.numero_documento },
        { nombre: "Nombres", id: "nombres", valor: persona?.nombres },
        { nombre: "Apellidos", id: "apellidos", valor: persona?.apellidos },
        { nombre: "Email", id: "email", valor: persona?.email },
        { nombre: "Fecha Nac.", id: "fecha_nacimiento", valor: persona?.fecha_nacimiento },
        { nombre: "Razón Social", id: "razon_social", valor: persona?.razon_social },
        { nombre: "DV", id: "dv", valor: persona?.dv },
        { nombre: "Activo", id: "activo", valor: persona?.activo },
        { nombre: "Acción" }
    ]

    async function cargarPersonas(id) {
        try {
            const resultado = await axios.get(`${apiCloudsystem}/personas/${id}`)
            setPersona(resultado.data.objeto)
        } catch (error) {
            console.log(error.message);
        }

        setCarga(false)
    }

    function manejadorActualizar(id) {
        setAbrirModal(true)
    }

    // function manejadorEliminar(id) {

    // }

    useEffect(() => {
        cargarPersonas(id)
    }, [])

    return (
        <div>
            {
                carga ? <Loader /> : <div>
                    <h2 className="text-xl">Persona</h2>
                    <div className="overflow-x-auto mt-4">
                        <table className="border-2 border-gray-900">
                            <thead>
                                <tr className="bg-gray-900 text-white">
                                    {
                                        cabeceraPersona.map((elemento, index) => {
                                            return <th className="border-r-2 border-gray-700" key={index}>{elemento.nombre}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-b-2 border-gray-900" >
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{persona.numero_documento}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{persona.nombres}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{persona.apellidos}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{persona.email}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{obtenerFechaDeTiempo(persona.fecha_nacimiento)}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{persona.razon_social}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{persona.dv}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >{persona.activo}</td>
                                    <td className="border-r-2 border-gray-900 bg-gray-700" >
                                        <div className="flex gap-1">
                                            <button onClick={() => manejadorActualizar(persona.id_persona)} className="bg-gray-900 hover:bg-gray-800 rounded-md p-2 text-white">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            {/* <button onClick={manejadorEliminar} className="bg-red-600 hover:bg-red-700 p-2 rounded-md text-white" >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button> */}

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Modal show={abrirModal} >
                        <div>
                            <form>
                                <div>

                                    {
                                        cabeceraPersona.map( (elemento, index) => {
                                            if(elemento.nombre === "Acción" ) return
                                            return <div key={index} >
                                                <div>
                                                    <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                </div>
                                                <TextInput id={elemento.id} value={ elemento.nombre !== "Fecha Nac." ? elemento.valor : obtenerFechaDeTiempo(elemento.valor) }  />
                                            </div>
                                        })
                                    }
                                </div>
                            </form>
                        </div>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default Persona