import { useEffect, useState } from "react";

import { Badge, Spinner, ToggleSwitch } from "flowbite-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight, faGear } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";

import { apiProcesos } from "../../recursos/configuracion";

import Loader from "../../componentes/Loader";


function Procesos() {
  // Lista de procesos (por defecto vacío)
  const [procesos, setProcesos] = useState([]);

  // Booleano que se encargará de mostrar el spinner
  const [carga, setCarga] = useState(true);

  // Llama a la api para traer el array de procesos
  async function cargarProcesos() {
    setCarga(true);
    try {
      const resultado = await Axios.get(`${apiProcesos}/procesos`);
      setProcesos(resultado.data.lista);
      console.log(procesos);
    } catch (error) {
      console.log(error);
    }
    setCarga(false);
  }

  // Se ejecuta cada vez que el switch reciba cambio
  async function manejadorDeSwitch(instancia) {
    try {
      const resultado = await Axios.put(
        `${apiProcesos}/procesos?instancia=${instancia}`
      );
      await cargarProcesos();
    } catch (error) {
      console.log(error);
    }
  }

  // Cada 15 segundos llama a la función cargarProcesos
  useEffect(() => {
    const interval = setInterval(() => {
      cargarProcesos();
    }, 15000);

    return () => clearInterval(interval);
  }, [carga]);

  return (
    <div className="flex flex-col gap-8">
      <h2 className="sub-titulo"> {carga ? <Spinner color="info" size="md" /> : <FontAwesomeIcon icon={faGear} />} Procesos</h2>
      {procesos.length === 0 ? <Loader /> : <ul className="flex flex-col gap-4">
        {procesos?.map((proceso) => {
          return <li key={proceso.instancia} className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <span> {proceso.instancia}. </span>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <ToggleSwitch onChange={(e) => manejadorDeSwitch(proceso.instancia)} checked={proceso.estado == 1 ? true : false} />
                  <span className="font-bold text-md"> {proceso.nombre} </span>
                </div>
                <div className="flex gap-2">
                  <Badge bg="primary" color="dark">
                    <div className="flex gap-1">
                      <span>
                        {proceso.origen} <FontAwesomeIcon icon={faArrowRight} />{" "}
                        {proceso.destino}
                      </span>
                    </div>
                  </Badge>
                  <span> {proceso.descrip} </span>
                </div>
              </div>
            </div>
            <Badge color={proceso.ejecucion === 1 ? "success" : "red"}> {proceso.ejecucion === 1 ? "En ejecución" : "Detenido"} </Badge>
          </li>
        })}

      </ul>}
      <div></div>

    </div>
  );
}

export default Procesos;
