import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, Select, TextInput } from "flowbite-react";
import { NavLink } from "react-router-dom";

const cabeceraData = [
    {
        nombre: "Vendedor",
    },
    {
        nombre: "Condición",
    },
    {
        nombre: "Cod.",
    },
    {
        nombre: "Cliente",
    },
    {
        nombre: "Es Cliente?",
    },
    {
        nombre: "Fecha",
    },
    {
        nombre: "Estado",
    },
    {
        nombre: "Referencista",
    },
    {
        nombre: "Analista",
    },
    {
        nombre: "Hora",
    },
]

const data = [
    {
        Id: 1,
        Vendedor: "John Doe",
        Condicion: "Débito",
        Cod: 12345,
        Cliente: "Alice",
        EsCliente: "Sí",
        Fecha: "27/11/2023",
        Estado: "Pendiente Vendedor",
        Referencista: "Jane",
        Analista: "Bob",
        Hora: "08:30:00",
    },
    {
        Id: 2,
        Vendedor: "Jane Smith",
        Condicion: "Crédito",
        Cod: 56789,
        Cliente: "Bob",
        EsCliente: "",
        Fecha: "28/11/2023",
        Estado: "Pendiente Analista",
        Referencista: "John",
        Analista: "Alice",
        Hora: "15:45:00",
    },
    {
        Id: 3,
        Vendedor: "Bob Johnson",
        Condicion: "Débito",
        Cod: 67890,
        Cliente: "Charlie",
        EsCliente: "Sí",
        Fecha: "29/11/2023",
        Estado: "Pendiente Referencista",
        Referencista: "Jane",
        Analista: "",
        Hora: "12:00:00",
    },
    {
        Id: 4,
        Vendedor: "Bob Johnson",
        Condicion: "Débito",
        Cod: 67890,
        Cliente: "Charlie",
        EsCliente: "Sí",
        Fecha: "29/11/2023",
        Estado: "Pendiente Referencista",
        Referencista: "Jane",
        Analista: "",
        Hora: "12:00:00",
    },
    {
        Id: 5,
        Vendedor: "Bob Johnson",
        Condicion: "Débito",
        Cod: 67890,
        Cliente: "Charlie",
        EsCliente: "Sí",
        Fecha: "29/11/2023",
        Estado: "Pendiente Referencista",
        Referencista: "Jane",
        Analista: "",
        Hora: "12:00:00",
    },
    {
        Id: 6,
        Vendedor: "Bob Johnson",
        Condicion: "Débito",
        Cod: 67890,
        Cliente: "Charlie",
        EsCliente: "Sí",
        Fecha: "29/11/2023",
        Estado: "Pendiente Referencista",
        Referencista: "Jane",
        Analista: "",
        Hora: "12:00:00",
    },
    {
        Id: 7,
        Vendedor: "Bob Johnson",
        Condicion: "Débito",
        Cod: 67890,
        Cliente: "Charlie",
        EsCliente: "Sí",
        Fecha: "29/11/2023",
        Estado: "Pendiente Referencista",
        Referencista: "Jane",
        Analista: "",
        Hora: "12:00:00",
    },
    {
        Id: 8,
        Vendedor: "Bob Johnson",
        Condicion: "Débito",
        Cod: 67890,
        Cliente: "Charlie",
        EsCliente: "Sí",
        Fecha: "29/11/2023",
        Estado: "Pendiente Referencista",
        Referencista: "Jane",
        Analista: "",
        Hora: "12:00:00",
    },
    {
        Id: 9,
        Vendedor: "Bob Johnson",
        Condicion: "Débito",
        Cod: 67890,
        Cliente: "Charlie",
        EsCliente: "Sí",
        Fecha: "29/11/2023",
        Estado: "Pendiente Referencista",
        Referencista: "Jane",
        Analista: "",
        Hora: "12:00:00",
    },
    {
        Id: 10,
        Vendedor: "Eva Rodriguez",
        Condicion: "Crédito",
        Cod: 98765,
        Cliente: "David",
        EsCliente: "",
        Fecha: "05/12/2023",
        Estado: "Pendiente Vendedor",
        Referencista: "Samuel",
        Analista: "Sophia",
        Hora: "09:15:00",
    },
];

// console.log(data);


function Solicitudes() {
    return (
        <div className="text-gray-900 dark:text-white" >
            {/* <h3>A</h3> */}
            <div className="mt-8 flex justify-center items-center">
                <NavLink to="/ventas/nueva-solicitud">
                    <div className="flex items-center gap-2 bg-gray-900 dark:bg-verde text-white rounded-md p-3 cursor-pointer">
                        <FontAwesomeIcon size="xl" icon={faPlus} />
                        <span>Nueva Solicitud</span>
                    </div>
                </NavLink>
            </div>

            <h3 className="sub-titulo mt-4">Filtros</h3>
            <div className="mt-2 flex justify-between items-center gap-4">
                <div>
                    <div className="block">
                        <Label htmlFor="estado" value="Estado" />
                    </div>
                    <Select id="estado" required>
                        <option>Pendiente Vendedor</option>
                        <option>Pendiente Analista</option>
                        <option>Pendiente Referencista</option>
                        <option>Aprobado</option>
                    </Select>
                </div>

                <div >
                    <div className="flex items-end gap-2" >
                        <div className="">
                            <div>
                                <Label htmlFor="RUC" value="RUC / CI" />
                            </div>
                            <TextInput className="max-w-[150px]" id="RUC" />
                        </div>
                        <div>
                            <div>
                                <Label htmlFor="nombre_cliente" value="Nombre del Cliente" />
                            </div>
                            <TextInput id="nombre_cliente" />
                        </div>
                        <div className="grid items-center p-[11px] bg-gray-900 text-white rounded-lg hover:bg-gray-700">
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="flex gap-2 items-end">
                        <div>
                            <div>
                                <Label htmlFor="código" value="Código" />
                            </div>
                            <TextInput className="max-w-[100px]" id="código" />
                        </div>
                        <div>
                            <div>
                                <Label htmlFor="nombre_vendedor" value="Nombre del vendedor" />
                            </div>
                            <Select id="nombre_vendedor" required>
                                <option>Nombre 1</option>
                                <option>Nombre 2</option>
                                <option>Nombre 3</option>
                            </Select>
                        </div>
                        <div>
                            <div>
                                <Label htmlFor="rango_fechas" value="Rango de Fechas" />
                            </div>
                            <div className="flex gap-1">
                                <TextInput className="max-w-[120px]" id="rango_fechas" placeholder="dd/mm/yyyy" />
                                <TextInput className="max-w-[120px]" placeholder="dd/mm/yyyy" />
                            </div>

                        </div>
                        <div className="grid items-center p-[11px] bg-gray-900 text-white rounded-lg hover:bg-gray-700">
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                    </div>
                </div>

            </div>






            <h2 className="sub-titulo mt-8">Solicitudes</h2>

            <div className="overflow-x-auto mt-4">
                <table className="border-2 border-gray-900">
                    <thead>
                        <tr>
                            {
                                cabeceraData.map((elemento, index) => {
                                    return <th className="bg-gray-900 text-white border-r-2 border-gray-500 py-4 last:border-0" key={index} >{elemento.nombre}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((elemento, index) => {
                                return (
                                    <tr className="border-b-2 border-gray-900" key={index}>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Vendedor}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Condicion}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Cod}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Cliente}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.EsCliente}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Fecha}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Estado}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Referencista}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Analista}</td>
                                        <td className="border-r-2 border-gray-900 dark:bg-gray-700 py-2">{elemento.Hora}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>



        </div>
    )
}

export default Solicitudes