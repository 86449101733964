import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { Spinner, Alert } from "flowbite-react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";
import { apiRiesgos } from "../../recursos/configuracion";
import {
  obtenerFechaDeTiempo,
  obtenerHoraDeTiempo,
  separadorDeMiles,
} from "../../recursos/funciones";

import { Label, Select, TextInput } from "flowbite-react";


function Consulta() {

  const [documento, setDocumento] = useState("");
  const [pais, setPais] = useState("");
  const [tipoDoc, setTipoDoc] = useState("");

  const [listaPais, setListaPais] = useState([]);
  const [listaTipoDoc, setListaTipoDoc] = useState([]);

  const [datos, setDatos] = useState();

  const [habilitacion, setHabilitacion] = useState(true);
  const [carga, setCarga] = useState(false);

  const [codigo, setCodigo] = useState(0);
  const [descripcion, setDescripcion] = useState("");

  const parametros = useParams();


  useEffect(() => {
    cargarListaTipoDoc();
    cargarListaPais();
  }, []);

  useEffect(() => {
    if (listaTipoDoc.length > 0 || listaPais.length > 0) {
      setHabilitacion(false);
    } else {
      setHabilitacion(true);
    }
  }, [listaTipoDoc, listaPais]);

  // useEffect(() => {
  //   //console.log(parametros);
  // }, [parametros]);

  // Se usa para crear los items de la lista 
  const informacionPersonalLista = [
    "Tipo Persona",
    "Razon Social",
    "Nombre Completo",
    "Primer Nombre",
    "Segundo Nombre",
    "Primer Apellido",
    "Segundo Apellido",
    "Fecha Nacimiento",
    "Genero",
    "Estado Civil",
    "Sector Economico",
    "Valor Score",
  ];

  // Para el mismo propósito que el de arriba
  const totalesLista = [
    "Información Adicional",
    "Información Funcionario Público",
    "Documentos",
    "Direcciones",
    "Consolidado Activo",
    "Detalles Traza de Búsqueda",
    "Marca Alerta",
    "Operaciones Activas",
    "Operaciones Canceladas",
    "Tarjetas",
    "Trabajos",
    "Histórico Score",
  ];

  // Trae los datos relacionados a la persona consultada
  async function consultarDocumento(e) {
    e.preventDefault()
    try {
      setCarga(true);
      let resultado = await Axios.post(
        `${apiRiesgos}/consultas/obtenerPersonaSSC`,
        {
          tipo: tipoDoc,
          pais: pais,
          documento: documento,
        }
      );
      setDatos({});
      setCodigo(resultado.data.codigo);
      setDescripcion(resultado.data.descripcion);

      if (resultado.data.codigo === -1) {
        console.log(resultado.data);
      } else {
        setDatos(resultado.data.objetoJson);
      }
    } catch (error) {
      console.log(error);
    }
    setCarga(false);
  }

  // Para cargar las options del form
  async function cargarListaTipoDoc() {
    try {
      let resultado = await Axios.get(
        `${apiRiesgos}/consultas/listaTipoDocumento`
      );
      setListaTipoDoc(resultado.data.arrayJson);
    } catch (error) {
      console.log(error);
    }
  }

  // Mismo propósito que el de arriba
  async function cargarListaPais() {
    try {
      let resultado = await Axios.get(`${apiRiesgos}/consultas/listaPaises`);
      // console.log("paises", resultado.data.arrayJson);
      setListaPais(resultado.data.arrayJson);
    } catch (error) {
      console.log(error);
    }
  }

  
  return (
    <div>
      <div className="grid gap-8 mt-8 lg:grid-cols-3">
        <div>
          <h4 className="sub-titulo text-gray-900 dark:text-white">Datos</h4>
          {/* Form para obtener los datos a consultar */}
          <form className="flex flex-col gap-2">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="nro_documento" value="Nro. Documento" />
              </div>
              <TextInput
                id="nro_documento"
                type="text"
                placeholder="Nro de documento"
                value={documento}
                onChange={(e) => setDocumento(e.target.value)}
                required
              />
            </div>

            <div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="documentos" value="Documentos" />
                </div>
                <Select id="documentos" onChange={(e) => setTipoDoc(e.target.value)} required>
                  {listaTipoDoc.map((pais, llave) => (
                    <option value={pais.idTipoDoc} key={llave}>
                      {pais.descripcion}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="paises" value="Países" />
                </div>
                <Select id="paises" onChange={(e) => setPais(e.target.value)} required>
                  {listaPais.map((pais, llave) => (
                    <option value={pais.idPais} key={llave}>
                      {pais.descripcion}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <button type="submit" className="bg-azulDark dark:bg-verde text-white  rounded-md py-2 hover:bg-blue-700 
          hover:text-white transition-colors" onClick={consultarDocumento}>
              {carga ? (
                <Spinner size="sm" />
              )
                : (
                  <FontAwesomeIcon icon={faSearch} />
                )}{" "}
              Consultar
            </button>

            <Alert color={codigo === 0 ? "success" : "failure"}>
              <span>
                {codigo === 0
                  ? "Consulta procesada"
                  : "Consulta no procesada"}
              </span>
              <p>
                Recuerde que esta consulta se puede hacer cada x
                segundos.
              </p>
              {/* <hr /> */}
              <p className="mb-0">{descripcion}</p>
            </Alert>

          </form>
        </div>

        <div className="border-blackish dark:border-gray-500 border-2 rounded-md p-4 text-gray-900 dark:text-white">
          <h4 className="sub-titulo text-center" >Información Personal</h4>
          <ol>
            {informacionPersonalLista.map((elemento, llave) => {
              return (
                <li key={llave}>
                  <a href="#hey">
                    {" "}
                    <span className="text-lg"> {llave + 1}. </span> {elemento}{" "}
                  </a>{" "}
                </li>
              );
            })}
          </ol>
        </div>

        <div className="border-blackish dark:border-gray-500 border-2 rounded-md p-4 text-gray-900 dark:text-white">
          <h4 className="sub-titulo text-center">Totales</h4>
          <ol>
            {totalesLista.map((elemento, llave) => {
              return (
                <li key={llave}>
                  {" "}
                  <a href="">
                    {" "}
                    <span className="text-lg"> {llave + 1}. </span> {elemento}{" "}
                  </a>{" "}
                </li>
              );
            })}
          </ol>
        </div>


      </div>


      <div className="mt-12" ></div>
      {/* El renderizado de las tablas empieza acá */}
      {datos && <div className="flex flex-col gap-10" id="consulta" >
        <div>
          {datos.documentos === undefined || datos.documentos === null ? (
            ""
          ) : (
            <div className="overflow-auto" >
              <table className="tabla-consulta" id="hey">
                <thead>
                  <tr>
                    <th className="bg-gray-800 text-white" >
                      Tipo Documento
                    </th>
                    <th className="bg-gray-800 text-white" >
                      Número Documento
                    </th>
                    <th className="bg-gray-800 text-white">
                      Versión Documento
                    </th>
                    <th className="bg-gray-800 text-white">País</th>
                    <th className="bg-gray-800 text-white">
                      Fecha Vencimiento
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {datos.documentos.map((documento, llave) => (
                    <tr key={llave}>
                      <td>{documento.tipoDoc}</td>
                      <td>
                        {separadorDeMiles(documento.nroDoc)}
                      </td>
                      <td>{documento.versionDocumento}</td>
                      <td>{documento.paisDoc}</td>
                      <td>
                        {obtenerFechaDeTiempo(documento.fechaVencimiento)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div>
          {datos.direcciones === undefined || datos.direcciones === null ? (
            ""
          ) : (
            <div className="overflow-auto">
              <table className="tabla-consulta">
                <thead>
                  <tr>
                    <th className="bg-gray-800 text-white">País</th>
                    <th className="bg-gray-800 text-white">Ciudad</th>
                    <th className="bg-gray-800 text-white">Barrio</th>
                    <th className="bg-gray-800 text-white">
                      Código Postal
                    </th>
                    <th className="bg-gray-800 text-white">Tipo</th>
                    <th className="bg-gray-800 text-white">
                      Dirección Libre
                    </th>
                    <th className="bg-gray-800 text-white">Edificio</th>
                    <th className="bg-gray-800 text-white">
                      Número Apartamento
                    </th>
                    <th className="bg-gray-800 text-white">
                      Número Puerta
                    </th>
                    <th className="bg-gray-800 text-white">Telefono</th>
                    <th className="bg-gray-800 text-white">
                      Fecha Registro
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {datos.direcciones.map((direccion, llave) => (
                    <tr key={llave}>
                      <td>{direccion.descripcionPais}</td>
                      <td>{direccion.ciudad}</td>
                      <td>{direccion.barrio}</td>
                      <td>{direccion.codigoPostal}</td>
                      <td>{direccion.descripcionTipoDireccion}</td>
                      <td>{direccion.direccion_Libre}</td>
                      <td>{direccion.edificio}</td>
                      <td>{direccion.nroApartamento}</td>
                      <td>{direccion.nroPuerta}</td>
                      <td>{direccion.telefono}</td>
                      <td>
                        {obtenerFechaDeTiempo(direccion.fechaRegistrada)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="overflow-auto">
          {datos.lInfoAdicional === undefined || datos.lInfoAdicional === null ? (
            ""
          ) : (
            <table className="tabla-consulta" >
              <thead className="overflow-x-scroll">
                <tr>
                  <th className="bg-gray-800 text-white" >Nombres</th>
                  <th className="bg-gray-800 text-white" >Apellidos</th>
                  <th className="bg-gray-800 text-white" >Documento</th>
                  <th className="bg-gray-800 text-white" >Tipo Documento</th>
                  <th className="bg-gray-800 text-white" >Sexo</th>
                  <th className="bg-gray-800 text-white">Estado Civil</th>
                  <th className="bg-gray-800 text-white">Lugar Nacimiento</th>
                  <th className="bg-gray-800 text-white">Fecha Nacimiento</th>
                  <th className="bg-gray-800 text-white">Fecha Imp</th>
                  <th className="bg-gray-800 text-white"> Domicilio</th>
                  <th className="bg-gray-800 text-white">Barrio Ciudad</th>
                  <th className="bg-gray-800 text-white">Cónyuge</th>
                  <th className="bg-gray-800 text-white">Madre</th>
                  <th className="bg-gray-800 text-white">Padre</th>
                </tr>
              </thead>
              <tbody>
                {datos.lInfoAdicional.map((adicional, llave) => (
                  <tr key={llave}>
                    <td>{adicional.nombres}</td>
                    <td>{adicional.apellidos}</td>
                    <td>{adicional.ci}</td>
                    <td>{adicional.tipoDoc}</td>
                    <td>{adicional.sexo}</td>
                    <td>{adicional.estadoCivil}</td>
                    <td>{adicional.lugarNacimiento}</td>
                    <td>{adicional.fechaNac}</td>
                    <td>{adicional.fechaImp}</td>
                    <td>{adicional.domicilio}</td>
                    <td>{adicional.barrio_Ciudad}</td>
                    <td>{adicional.nombreConyuge}</td>
                    <td>{adicional.nombreMadre}</td>
                    <td>{adicional.nombrePadre}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="overflow-auto">
          {datos.lInfoFuncionarioPublico === undefined ||
            datos.lInfoFuncionarioPublico === null ? (
            ""
          ) : (
            <table className="tabla-consulta">
              <thead >
                <tr>
                  <th className="bg-gray-800 text-white">Entidad</th>
                  <th className="bg-gray-800 text-white">Gasto</th>
                  <th className="bg-gray-800 text-white">Categoría</th>
                  <th className="bg-gray-800 text-white">Documento</th>
                  <th className="bg-gray-800 text-white">Nombres</th>
                  <th className="bg-gray-800 text-white">Monto</th>
                  <th className="bg-gray-800 text-white">Mes</th>
                  <th className="bg-gray-800 text-white">Año</th>
                  <th className="bg-gray-800 text-white">Vínculo</th>
                </tr>
              </thead>
              <tbody>
                {datos.lInfoFuncionarioPublico.map((funcionario, llave) => (
                  <tr key={llave}>
                    <td>{funcionario.entidad}</td>
                    <td>{funcionario.objetoGasto}</td>
                    <td>{funcionario.categoria}</td>
                    <td>
                      {separadorDeMiles(funcionario.cedula)}
                    </td>
                    <td>{funcionario.nombres}</td>
                    <td>
                      {separadorDeMiles(funcionario.monto)}
                    </td>
                    <td>{funcionario.mes}</td>
                    <td>{funcionario.anho}</td>
                    <td>{funcionario.vinculo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>


        <div className="overflow-auto">
          {datos.consolidadoActivo === undefined ||
            datos.consolidadoActivo === null ? (
            ""
          ) : (
            <table className="tabla-consulta">
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white">
                    Fecha Corte
                  </th>
                  <th className="bg-gray-800 text-white">Hora Corte</th>
                  <th className="bg-gray-800 text-white">
                    Cantidad Cheques Rechazadas Formal
                  </th>
                  <th className="bg-gray-800 text-white">
                    Cantidad Cheques Rechazadas Informal
                  </th>
                  <th className="bg-gray-800 text-white">
                    Cantidad Cheques Rechazadas Total
                  </th>
                  <th className="bg-gray-800 text-white">
                    Cantidad Consultas Realizadas
                  </th>
                  <th className="bg-gray-800 text-white">
                    Entidades Aportantes Consumo
                  </th>
                  <th className="bg-gray-800 text-white">
                    Entidades Aportantes Libranza
                  </th>
                  <th className="bg-gray-800 text-white">
                    Entidades Aportantes Total
                  </th>
                  <th className="bg-gray-800 text-white">
                    Máximo días atrasos
                  </th>
                  <th className="bg-gray-800 text-white">
                    Máximo Cuotas Pendientes
                  </th>
                  <th className="bg-gray-800 text-white">
                    Mayor plazo Credito
                  </th>
                  <th className="bg-gray-800 text-white">
                    Menor plazo Credito
                  </th>
                  <th className="bg-gray-800 text-white">
                    Mínimo Cuotas Pendientes
                  </th>
                  <th className="bg-gray-800 text-white">
                    Monto Cheques Rechazados Formal
                  </th>
                  <th className="bg-gray-800 text-white">
                    Monto Cheques Rechazados Informal
                  </th>
                  <th className="bg-gray-800 text-white">
                    Monto Cheques Rechazados Total
                  </th>
                  <th className="bg-gray-800 text-white">
                    Monto Cuota Consolidado
                  </th>
                  <th className="bg-gray-800 text-white">
                    Monto Cuota Vencido
                  </th>
                  <th className="bg-gray-800 text-white">
                    Promedio Pagos
                  </th>
                  <th className="bg-gray-800 text-white">
                    Saldo Consolidado
                  </th>
                  <th className="bg-gray-800 text-white">
                    Garantía BCP
                  </th>
                </tr>
              </thead>
              <tbody>
                {datos.consolidadoActivo.map((activo, llave) => (
                  <tr key={llave}>
                    <td>
                      {obtenerFechaDeTiempo(activo.fechaCorte)}
                    </td>
                    <td>
                      {obtenerHoraDeTiempo(activo.fechaCorte)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.cantChequesRechazadosFormal)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.cantChequesRechazadosInformal)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.cantChequesRechazadosTotal)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.cantConsultasRealizadas)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.entidadesAportantesConsumo)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.entidadesAportantesLibranza)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.entidadesAportantesTotal)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.max_Dias_Atraso)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.maximoCuotasPendientes)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.mayorPlazoCredito)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.menorPlazoCredito)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.minimoCuotasPendientes)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.montoChequesRechazadosFormal)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.montoCheqyesRechazadosInformal)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.montosChequesRechazadosTotal)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.montoCuota_Consolidado)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.montoCuota_Vencido)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.promedioPagos)}
                    </td>
                    <td>
                      {separadorDeMiles(activo.saldoConsolidado)}
                    </td>
                    <td>{activo.tipoGarantiaBCP}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="overflow-auto">
          {datos.detallesTrazasBusquedas === undefined ||
            datos.detallesTrazasBusquedas === null ? (
            ""
          ) : (
            <table className="tabla-consulta" >
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white">Fecha</th>
                  <th className="bg-gray-800 text-white">Hora</th>
                  <th className="bg-gray-800 text-white">
                    Institución
                  </th>
                  <th className="bg-gray-800 text-white">Usuario</th>
                  <th className="bg-gray-800 text-white">Tipo</th>
                </tr>
              </thead>
              <tbody>
                {datos.detallesTrazasBusquedas.map((traza, llave) => (
                  <tr key={llave}>
                    <td>
                      {obtenerFechaDeTiempo(traza.fechaHoraBusqueda)}
                    </td>
                    <td>
                      {obtenerHoraDeTiempo(traza.fechaHoraBusqueda)}
                    </td>
                    <td>{traza.nombreInstitucionBusqueda}</td>
                    <td>{traza.usuarioBusqueda}</td>
                    <td>
                      {traza.tipoConsultaProducto.descripcion}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="overflow-auto">
          {datos.marcasAlertas === undefined || datos.marcasAlertas === null ? (
            ""
          ) : (
            <table className="tabla-consulta" >
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white">Fecha</th>
                  <th className="bg-gray-800 text-white">
                    Institución
                  </th>
                  <th className="bg-gray-800 text-white">Contacto</th>
                  <th className="bg-gray-800 text-white">Usuario</th>
                </tr>
              </thead>
              <tbody>
                {datos.marcasAlertas.map((marcas, llave) => (
                  <tr key={llave}>
                    <td>{marcas.fecha}</td>
                    <td>{marcas.institucion_Desc}</td>
                    <td>{marcas.infoContacto}</td>
                    <td>{marcas.usuario}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="overflow-auto">
          {datos.operacionesActivasDeLaPersona === undefined ||
            datos.operacionesActivasDeLaPersona === null ? (
            ""
          ) : (
            <table className="tabla-consulta" >
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white">
                    Institución
                  </th>
                  <th className="bg-gray-800 text-white">Operación</th>
                  <th className="bg-gray-800 text-white">Fecha</th>
                  <th className="bg-gray-800 text-white">Moneda</th>
                  <th className="bg-gray-800 text-white">Período</th>
                  <th className="bg-gray-800 text-white">
                    Vencimiento
                  </th>
                  <th className="bg-gray-800 text-white">
                    Capital Adeudado Actual
                  </th>
                  <th className="bg-gray-800 text-white">
                    Capital Atrasado
                  </th>
                  <th className="bg-gray-800 text-white">
                    Capital Original
                  </th>
                  <th className="bg-gray-800 text-white">
                    Días Atraso
                  </th>
                  <th className="bg-gray-800 text-white">
                    Días Atraso Maximo
                  </th>
                  <th className="bg-gray-800 text-white">
                    Días Atraso Promedio
                  </th>
                  <th className="bg-gray-800 text-white">
                    Interés Atrasado
                  </th>
                  <th className="bg-gray-800 text-white">
                    Interés Original
                  </th>
                  <th className="bg-gray-800 text-white">
                    Interés Pendiente de Devengar
                  </th>
                  <th className="bg-gray-800 text-white">Mora Paga</th>
                  <th className="bg-gray-800 text-white">
                    Mora Pendiente de Pago
                  </th>
                  <th className="bg-gray-800 text-white">
                    Plazo Remanente en Periodos
                  </th>
                  <th className="bg-gray-800 text-white">
                    Plazo Total en Periodos
                  </th>
                  <th className="bg-gray-800 text-white">
                    Total Atraso
                  </th>
                  <th className="bg-gray-800 text-white">
                    Total Deuda
                  </th>
                  <th className="bg-gray-800 text-white">
                    Valor Cuota
                  </th>
                </tr>
              </thead>
              <tbody>
                {datos.operacionesActivasDeLaPersona.map((activa, llave) => (
                  <tr key={llave}>
                    <td>{activa.nombreInstitucion}</td>
                    <td>{activa.descripcionTipoOperacion}</td>
                    <td>
                      {obtenerFechaDeTiempo(activa.fechaOperacion)}
                    </td>
                    <td>{activa.descripcionMoneda}</td>
                    <td>{activa.descripcionPeriodoPrestamo}</td>
                    <td>
                      {obtenerFechaDeTiempo(activa.vencimiento)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.capitalAdeudadoActual)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.capitalAtrasado)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.capitalOriginal)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.diasAtraso)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.diasAtrasoMaximo)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.diasAtrasoPromedio)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.interesAtrasado)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.interesOriginal)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.interesPendienteDeDevengar)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.moraPaga)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.moraPendienteDePago)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.plazoRemanenteEnPeriodos)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.plazoTotalEnPeriodos)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.totalAtraso)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.totalDeuda)}
                    </td>
                    <td>
                      {separadorDeMiles(activa.valorCuota)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="overflow-auto">
          {datos.operacionesCanceladasDeLaPersona === undefined ||
            datos.operacionesCanceladasDeLaPersona === null ? (
            ""
          ) : (
            <table className="tabla-consulta" >
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white">
                    Institución
                  </th>
                  <th className="bg-gray-800 text-white">Operación</th>
                  <th className="bg-gray-800 text-white">Fecha</th>
                  <th className="bg-gray-800 text-white">Moneda</th>
                  <th className="bg-gray-800 text-white">
                    Capital Original
                  </th>
                  <th className="bg-gray-800 text-white">
                    Días Atraso Máximo
                  </th>
                  <th className="bg-gray-800 text-white">
                    Días Atraso Promedio
                  </th>
                  <th className="bg-gray-800 text-white">
                    Intereses generados
                  </th>
                  <th className="bg-gray-800 text-white">
                    Mora Generada
                  </th>
                  <th className="bg-gray-800 text-white">
                    Quita Capital
                  </th>
                  <th className="bg-gray-800 text-white">
                    Quita Interes
                  </th>
                  <th className="bg-gray-800 text-white">Quita Mora</th>
                  <th className="bg-gray-800 text-white">
                    Cancelación
                  </th>
                </tr>
              </thead>
              <tbody>
                {datos.operacionesCanceladasDeLaPersona.map(
                  (cancelada, llave) => (
                    <tr key={llave}>
                      <td>{cancelada.nombreInstitucion}</td>
                      <td>{cancelada.descripcionTipoOperacion}</td>
                      <td>
                        {obtenerFechaDeTiempo(cancelada.fechaOperacion)}
                      </td>
                      <td>{cancelada.descripcionMoneda}</td>
                      <td>
                        {separadorDeMiles(cancelada.capitalOriginal)}
                      </td>
                      <td>
                        {separadorDeMiles(cancelada.diasAtrasoMaximo)}
                      </td>
                      <td>
                        {separadorDeMiles(cancelada.diasAtrasoPromedio)}
                      </td>
                      <td>
                        {separadorDeMiles(cancelada.montoInteresGenerado)}
                      </td>
                      <td>
                        {separadorDeMiles(cancelada.montoMoraGenerada)}
                      </td>
                      <td>
                        {separadorDeMiles(cancelada.montoQuitaCapital)}
                      </td>
                      <td>
                        {separadorDeMiles(cancelada.montoQuitaInteres)}
                      </td>
                      <td>
                        {separadorDeMiles(cancelada.montoQuitaMora)}
                      </td>
                      <td>
                        {cancelada.descripcionTipoCancelacion}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
        </div>

        <div className="overflow-auto">
          {datos.tarjetas === undefined || datos.tarjetas === null ? (
            ""
          ) : (
            <table className="tabla-consulta" >
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          )}
        </div>


        <div className="overflow-auto">
          {datos.trabajosDeLaPersona === undefined ||
            datos.trabajosDeLaPersona === null ? (
            ""
          ) : (
            <table className="tabla-consulta" >
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white">
                    Lugar Trabajo
                  </th>
                  <th className="bg-gray-800 text-white">
                    Fecha Informado
                  </th>
                  <th className="bg-gray-800 text-white">Cargo</th>
                  <th className="bg-gray-800 text-white">Salario</th>
                  <th className="bg-gray-800 text-white">
                    Comprobante
                  </th>
                  <th className="bg-gray-800 text-white">
                    Dependiente
                  </th>
                </tr>
              </thead>
              <tbody>
                {datos.trabajosDeLaPersona.map((trabajo, llave) => (
                  <tr key={llave}>
                    <td>{trabajo.lugarDeTrabajo}</td>
                    <td>
                      {obtenerFechaDeTiempo(trabajo.fechaInformado)}
                    </td>
                    <td>{trabajo.cargoTrabajo}</td>
                    <td>
                      {separadorDeMiles(trabajo.salario)}
                    </td>
                    <td>{trabajo.comprobanteIngresoString}</td>
                    <td>{trabajo.esDependienteString}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="overflow-auto">
          {datos.historicosScore === undefined ||
            datos.historicosScore === null ? (
            ""
          ) : (
            <table className="tabla-consulta">
              <thead>
                <tr>
                  <th className="bg-gray-800 text-white">Año</th>
                  <th className="bg-gray-800 text-white">Mes</th>
                  <th className="bg-gray-800 text-white">Score</th>
                </tr>
              </thead>
              <tbody>
                {datos.historicosScore.map((score, llave) => (
                  <tr key={llave}>
                    <td>{score.anio}</td>
                    <td>{score.mes}</td>
                    <td>
                      {separadorDeMiles(score.score)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      }




    </div>
  );
}

export default Consulta;
