import { faArrowRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Label, Select, TextInput } from "flowbite-react"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

// DP: Dato Personal
// DL: Dato Laboral
// DR: Referencia

function NuevaSolicitud() {


    // Datos Personales - DP

    const valorInicialGeneralDP = {
        codigo: "",
        rucDP: "",
        dvDP: "",
        razon_social: "",
        fecha_nac: "",
    }

    const valorInicialDireccionDP = {
        departamentoDP: "",
        ciudadDP: "",
        barrioDP: "",
        calle_principalDP: "",
        calle_secundariaDP: "",
        nro_casaDP: ""
    }

    const valorInicialEmailDP = {
        emailDP: "",
        tipoEmailDP: "Personal"
    }

    const valorInicialTelefonoDP = {
        numeroTelDP: "",
        tipoTelDP: "Personal"
    }

    const valorInicialCelularDP = {
        numeroCelDP: "",
        tipoCelDP: "Personal"
    }


    // Datos Laborales - DL
    const valorInicialGeneralDL = {
        empresa: "",
        rubro: "",
        cargo: "",
        fecha_ing: "",
        fecha_egr: "",
        salario: "",
    }

    const valorInicialDireccionDL = {
        departamentoDL: "",
        ciudadDL: "",
        barrioDL: "",
        calle_principalDL: "",
        calle_secundariaDL: "",
        nro_casaDL: "",
    }

    const valorInicialContactoDL = {
        telefonoDL: "",
        celularDL: "",
        emailDL: ""
    }


    // Datos Referencia - DR

    const valorInicialDR = {
        rucDR: "",
        dvDR: "",
        nombresDR: "",
        apellidosDR: "",
        parentescoDR: "",
        telefonoDR: "",
        celularDR: "",
    }

    // Cada uno de los campos que puedan tomar más de un valor
    const [listaTrabajo, setListaTrabajo] = useState([])
    const [listaReferencia, setListaReferencia] = useState([])
    const [listaDireccion, setListaDireccion] = useState([])
    const [listaEmailDP, setListaEmailDP] = useState([])
    const [listaTelefonoDP, setListaTelefonoDP] = useState([])
    const [listaCelularDP, setListaCelularDP] = useState([])


    // Estado para manejar cada nuevo dato personal
    const [generalDP, setGeneralDP] = useState(valorInicialGeneralDP)

    // Estado para manejar cada nueva dirección
    const [direccionDP, setDireccionDP] = useState(valorInicialDireccionDP)

    // Estado para manejar cada nuevo email de DP
    const [emailDP, setEmailDP] = useState(valorInicialEmailDP)

    const [telefonoDP, setTelefonoDP] = useState(valorInicialTelefonoDP)

    const [celularDP, setCelularDP] = useState(valorInicialCelularDP)

    
    
    // Estado para manejar cada nuevo trabajo
    const [generalDL, setGeneralDL] = useState(valorInicialGeneralDL)

    const [direccionDL, setDireccionDL] = useState(valorInicialDireccionDL)

    const [contactoDL, setContactoDL] = useState(valorInicialContactoDL)


    
    // Estado para manejar cada nueva referencia
    const [referencia, setReferencia] = useState(valorInicialDR)

    
    // Array para iterar y crear los inputs y labels relacionados a datos personales
    const metadatosGeneralesDP = [
        { nombre: "Código", id: "codigo", valor: generalDP.codigo, tipo: "text" },
        { nombre: "RUC / CI", id: "rucDP", valor: generalDP.ruc, tipo: "number" },
        { nombre: "Razón Social", id: "razon_social", valor: generalDP.razon_social, tipo: "text" },
        { nombre: "Fecha Nac.", id: "fecha_nac", valor: generalDP.fecha_nac, tipo: "date" },
    ]

    // Array para iterar y crear los inputs con labels relacionados a dirección
    const metadatosDireccionesDP = [
        { nombre: "Departamento", id:"departamentoDP", valor: direccionDP.departamentoDP, tipo: "text" },
        { nombre: "Ciudad", id: "ciudadDP", valor: direccionDP.ciudadDP, tipo: "text" },
        { nombre: "Barrio", id: "barrioDP", valor: direccionDP.barrioDP, tipo: "text" },
        { nombre: "Calle Principal", id: "calle_principalDP", valor: direccionDP.calle_principalDP, tipo: "text" },
        { nombre: "Calle Secundaria", id: "calle_secundariaDP", valor: direccionDP.calle_secundariaDP, tipo: "text" },
        { nombre: "Nº Casa", id: "nro_casaDP", valor: direccionDP.nro_casaDP, tipo: "number" }
    ]

    // Array para iterar y crear los inputs y labels relacionados a datos laborales
    const metadatosGeneralesDL = [
        { nombre: "Empresa", id: "empresa", valor: generalDL.empresa, tipo: "text" },
        { nombre: "Rubro", id: "rubro", valor: generalDL.rubro, tipo: "text" },
        { nombre: "Cargo", id: "cargo", valor: generalDL.cargo, tipo: "text" },
        { nombre: "Fecha Ingreso", id: "fecha_ing", valor: generalDL.fecha_ing, tipo: "date" },
        { nombre: "Fecha Egreso", id: "fecha_egr", valor: generalDL.fecha_egr, tipo: "date" },
        { nombre: "Salario", id: "salario", valor: generalDL.salario, tipo: "number" },
    ]

    const metadatosDireccionesDL = [
        { nombre: "Departamento", id: "departamentoDL", valor: direccionDL.departamentoDL, tipo: "text" },
        { nombre: "Ciudad", id: "ciudadDL", valor: direccionDL.ciudadDL, tipo: "text" },
        { nombre: "Barrio", id: "barrioDL", valor: direccionDL.barrioDL, tipo: "text" },
        { nombre: "Calle Principal", id: "calle_principalDL", valor: direccionDL.calle_principalDL, tipo: "text" },
        { nombre: "Calle Secundaria", id: "calle_secundariaDL", valor: direccionDL.calle_secundariaDL, tipo: "text" },
        { nombre: "Nº de Casa", id: "nro_casaDL", valor: direccionDL.nro_casaDL, tipo: "number" },
    ]

    const metadatosContactoDL = [
        { nombre: "Email", id: "emailDL", valor: contactoDL.emailDL, tipo: "text" },
        { nombre: "Teléfono", id: "telefonoDL", valor: contactoDL.telefonoDL, tipo: "text" },
        { nombre: "Celular", id: "celularDL", valor: contactoDL.celularDL, tipo: "text" },
    ]

    // Para iterar y crear inputs con labels de manera dinámica
    const metadatosDR = [
        { nombre: "RUC / CI", id: "rucDR", valor: referencia.rucDR, tipo: "number" },
        { nombre: "Nombres", id: "nombresDR", valor: referencia.nombresDR, tipo: "text" },
        { nombre: "Apellidos", id: "apellidosDR", valor: referencia.apellidosDR, tipo: "text" },
        { nombre: "Parentesco", id: "parentescoDR", valor: referencia.parentescoDR, tipo: "text" },
        { nombre: "Teléfono", id: "telefonoDR", valor: referencia.telefonoDR, tipo: "text" },
        { nombre: "Celular", id: "celularDR", valor: referencia.celularDR, tipo: "text" }
    ]

    // Cabecera de tabla de Historial Laboral (se obtiene de todas las partes de DL)
    const cabeceraHistorial = [
        ...metadatosGeneralesDL,
        ...metadatosDireccionesDL,
        ...metadatosContactoDL
    ]

    // Se ejecuta cada vez que haya un cambio en los inputs
    function manejadorGeneralDP(e) {
        const { name, value } = e.target
        setGeneralDP(prev => ({ ...prev, [name]: value }))
    }

    function manejadorDireccionDP(e) {
        const { name, value } = e.target
        setDireccionDP(previo => ({ ...previo, [name]: value }))
    }

    function manejadorEmailDP(e) {
        const { name, value } = e.target
        setEmailDP(previo => ({ ...previo, [name]: value }))
    }

    function manejadorTelefonoDP(e) {
        const { name, value } = e.target
        console.log("name: ", name, "value: ", value);
        setTelefonoDP(previo => ({ ...previo, [name]: value }))
    }


    function manejadorCelularDP(e) {
        const { name, value } = e.target
        setCelularDP(previo => ({ ...previo, [name]: value }))
    }

    // Se ejecuta cada vez que haya un cambio en los inputs de datos laborales
    function manejadorGeneralDL(e) {
        const { name, value } = e.target
        setGeneralDL(prev => ({ ...prev, [name]: value }))
    }

    function manejadorDireccionDL(e) {
        const { name, value } = e.target
        setDireccionDL(prev => ({ ...prev, [name]: value }))
    }

    function manejadorContactoDL(e) {
        const { name, value } = e.target
        setContactoDL(prev => ({ ...prev, [name]: value }))
    }


    // Se ejecuta cada vez que haya un cambio en los inputs de datos referencia
    function manejadorDR(e) {
        const { name, value } = e.target
        setReferencia(previo => ({ ...previo, [name]: value }))
    }

    function manejadorAgregarDireccionDP() {
        setListaDireccion(previo => ([...previo, direccionDP]))
        setDireccionDP(valorInicialDireccionDP)
    }

    function manejadorAgregarEmailDP() {
        setListaEmailDP(previo => ([...previo, emailDP]))
        setEmailDP(valorInicialEmailDP)
    }

    function manejadorAgregarTelefonoDP() {
        setListaTelefonoDP(previo => [...previo, telefonoDP])
        setTelefonoDP(valorInicialTelefonoDP)
    }

    function manejadorAgregarCelularDP() {
        setListaCelularDP(previo => [...previo, celularDP])
        setCelularDP(valorInicialCelularDP)
    }

    // Se ejecuta cada vez que se agrega un nuevo trabajo
    function manejadorAgregarTrabajoDL(e) {
        e.preventDefault()
        setListaTrabajo(previo => ([...previo, {...generalDL, ...direccionDL, ...contactoDL }]))
        setGeneralDL(valorInicialGeneralDL)
        setDireccionDL(valorInicialDireccionDL)
        setContactoDL(valorInicialContactoDL)
    }

    function manejadorAgregarDR() {
        setListaReferencia(previo => ([...previo, referencia]))
        setReferencia(valorInicialDR)
    }

    return (
        <div className="overflow-y-auto text-gray-900 dark:text-white">
            <h2 className="sub-titulo">Nueva Solicitud</h2>
            <div className="overflow-y-auto">
                <div className="overflow-y-auto">
                    <div className="overflow-y-auto">
                        <h2 className="mt-8">Datos Personales</h2>
                        <form className="border-2 border-gray-100 rounded-md border-opacity-50 p-3 mt-2">
                            <h3 className="my-2 underline">Dato cliente</h3>
                            <div className="lg:grid lg:grid-cols-4 lg:gap-4">
                                {metadatosGeneralesDP.map((elemento, index) => {
                                    if (elemento.id !== "rucDP" && elemento.id !== "codigo") {
                                        return <div key={index}>
                                            <Label htmlFor={elemento.id} value={elemento.nombre} />
                                            <TextInput id={elemento.id} type={elemento.tipo} name={elemento.tipo} onChange={manejadorGeneralDP} />
                                        </div>
                                    } else if (elemento.id === "rucDP") {
                                        return <div key={index} className="flex flex-col justify-center" >
                                            <Label className="ml-1" htmlFor={elemento.id} value={elemento.nombre} name={elemento.id} />
                                            <div className="flex gap-1 items-center">
                                                <TextInput id={elemento.id} type={elemento.tipo} value={elemento.valor} onChange={manejadorGeneralDP} />
                                                <span> - </span>
                                                <TextInput type="number" className="max-w-[80px]" value={generalDP.dvDP} onChange={manejadorGeneralDP} />
                                            </div>
                                        </div>
                                    } else if (elemento.id === "codigo") {
                                        return <div key={index} >
                                            <Label className="ml-1" value={elemento.nombre}  />
                                            <div>
                                                <div className="ml-1 p-2 lg:bg-gray-700 rounded-md max-w-[200px]">0789043</div>
                                            </div>
                                        </div>
                                    }
                                })}

                                <div className="mt-4">
                                    <Label value="Tipo Cliente: " />
                                    <span className="ml-1 p-2 lg:bg-gray-700 rounded-md">Recurrente</span>
                                </div>
                            </div>

                            <div className="my-8 h-[1px] bg-gray-900 dark:bg-gray-500"></div>

                            <div>
                                <h3 className="underline">Dirección</h3>

                                <div className="mt-4 lg:grid lg:grid-cols-4 lg:gap-4">
                                    {metadatosDireccionesDP.map((elemento, index) => {
                                        return <div key={index}>
                                            <Label value={elemento.nombre} htmlFor={elemento.id} />
                                            <TextInput id={elemento.id} type={elemento.tipo} onChange={manejadorDireccionDP} name={elemento.id} value={elemento.valor} />
                                        </div>
                                    })}
                                </div>

                                <div className="mt-4">
                                    <Button onClick={manejadorAgregarDireccionDP}>Agregar</Button>
                                </div>

                                {

                                    listaDireccion.length > 0 &&
                                    <div className="overflow-x-auto mt-6">
                                        <h4>Lista de direcciones</h4>
                                        <table className="border-2 border-gray-900 mt-2">
                                            <thead>
                                                <tr className="bg-gray-900">
                                                    {metadatosDireccionesDP.map((elemento, index) => {
                                                        return <th className="border-r-2 border-gray-700 last:border-none" key={index} > {elemento.nombre} </th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {

                                                    listaDireccion.map((elemento, index) => {
                                                        return <tr className="bg-gray-700 border-b-2 border-gray-900" key={index}>
                                                            <td className="border-r-2 border-gray-900" > {elemento.departamentoDP} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.ciudadDP} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.barrioDP} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.calle_principalDP} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.calle_secundariaDP} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.nro_casaDP} </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>

                            <div className="my-8 h-[1px] bg-gray-900 dark:bg-gray-500"></div>

                            <div>
                                <h3 className="underline">Contacto</h3>

                                <div className="mt-4 lg:grid lg:items-start lg:grid-cols-3 lg:gap-8">
                                    <div>
                                        <div className="flex gap-1 items-center">
                                            <div>
                                                <Label value="Email" htmlFor="email" />
                                                <TextInput type="email" name="emailDP" value={emailDP.emailDP} id="email" onChange={manejadorEmailDP} />
                                            </div>

                                            <div>
                                                <div>
                                                    <Label htmlFor="email-select" value="Tipo" />
                                                </div>
                                                <Select id="email-select" onChange={manejadorEmailDP} name="tipoEmailDP" >
                                                    <option value="Personal">Personal</option>
                                                    <option value="Corporativo">Corporativo</option>
                                                </Select>
                                            </div>

                                            <div>
                                                <div className="h-6"></div>
                                                <Button onClick={manejadorAgregarEmailDP}>Agregar</Button>
                                            </div>
                                        </div>

                                        {
                                            listaEmailDP.length > 0 && <div className="mt-4 overflow-x-auto" >
                                                <h3>Emails</h3>
                                                <table className="border-2 border-gray-900" >
                                                    <thead className="bg-gray-900 dark:text-white">
                                                        <tr>
                                                            <th className="border-r-2 border-gray-700" >Email</th>
                                                            <th className="border-r-2 border-gray-700" >Tipo</th>
                                                            <th className="">Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            listaEmailDP.map((elemento, index) => {
                                                                return <tr className="bg-gray-700 border-b-2 border-gray-900" key={index} >
                                                                    <td className="border-r-2 border-gray-900"> {elemento.emailDP} </td>
                                                                    <td className="border-r-2 border-gray-900"> {elemento.tipoEmailDP} </td>
                                                                    <td className="border-r-2 border-gray-900">
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        <div className="flex items-center gap-1">
                                            <div>
                                                <Label value="Teléfono" htmlFor="telefono-select" />
                                                <TextInput type="text" id="telefono-select" value={telefonoDP.numeroTelDP} name="numeroTelDP"
                                                    onChange={manejadorTelefonoDP}
                                                />
                                            </div>

                                            <div>
                                                <div>
                                                    <Label htmlFor="tipo-telefono" value="Tipo" />
                                                </div>
                                                <Select id="tipo-telefono" name="tipoTelDP" onChange={manejadorTelefonoDP}>
                                                    <option value="Personal">Personal</option>
                                                    <option value="Corporativo">Corporativo</option>
                                                </Select>
                                            </div>

                                            <div>
                                                <span className="block h-6" ></span>
                                                <Button onClick={manejadorAgregarTelefonoDP}>Agregar</Button>
                                            </div>

                                        </div>

                                        {
                                            listaTelefonoDP.length > 0 && <div className="mt-4 overflow-x-auto" >
                                                <h3>Teléfonos</h3>
                                                <table className="border-2 border-gray-900" >
                                                    <thead className="bg-gray-900 dark:text-white">
                                                        <tr>
                                                            <th className="border-r-2 border-gray-700" >Número</th>
                                                            <th className="border-r-2 border-gray-700" >Tipo</th>
                                                            <th className="">Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            listaTelefonoDP.map((elemento, index) => {
                                                                return <tr className="bg-gray-700 border-b-2 border-gray-900" key={index} >
                                                                    <td className="border-r-2 border-gray-900" > {elemento.numeroTelDP} </td>
                                                                    <td className="border-r-2 border-gray-900" > {elemento.tipoTelDP} </td>
                                                                    <td className="border-r-2 border-gray-900" >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                    </div>


                                    <div>
                                        <div className="flex gap-1">
                                            <div>
                                                <Label value="Celular" htmlFor="numeroCelDP" />
                                                <TextInput type="text" id="numeroCelDP" name="numeroCelDP" value={celularDP.numeroCelDP} onChange={manejadorCelularDP} />
                                            </div>

                                            <div>
                                                <div>
                                                    <Label htmlFor="tipoCelDP" value="Tipo" />
                                                </div>
                                                <Select id="tipoCelDP" name="tipoCelDP" onChange={manejadorCelularDP} >
                                                    <option value="Personal">Personal</option>
                                                    <option value="Corporativo">Corporativo</option>
                                                </Select>
                                            </div>

                                            <div>
                                                <span className="block h-6"></span>
                                                <Button onClick={manejadorAgregarCelularDP}>Agregar</Button>
                                            </div>
                                        </div>

                                        {
                                            listaCelularDP.length > 0 && <div className="mt-4 overflow-x-auto" >
                                                <h3>Celulares</h3>
                                                <table className="border-2 border-gray-900" >
                                                    <thead className="bg-gray-900 dark:text-white">
                                                        <tr>
                                                            <th className="border-r-2 border-gray-700" >Número</th>
                                                            <th className="border-r-2 border-gray-700" >Tipo</th>
                                                            <th className="">Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            listaCelularDP.map((elemento, index) => {
                                                                return <tr className="bg-gray-700 border-b-2 border-gray-900" key={index} >
                                                                    <td className="border-r-2 border-gray-900" > {elemento.numeroCelDP} </td>
                                                                    <td className="border-r-2 border-gray-900" > {elemento.tipoCelDP} </td>
                                                                    <td className="border-r-2 border-gray-900" >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <h3 className="mt-14">Datos Laborales</h3>
                    <form className="mt-2 border-2 border-gray-300 rounded-md border-opacity-50 p-3">
                        <div>
                            <div className="mt-2 lg:grid lg:grid-cols-4 lg:gap-4">
                                {
                                    metadatosGeneralesDL.map((elemento, index) => {
                                        if (elemento.nombre === "Acción") return null
                                        return <div key={index}>
                                            <Label htmlFor={elemento.id} value={elemento.nombre} />
                                            <TextInput
                                                id={elemento.id}
                                                type={elemento.tipo}
                                                onChange={manejadorGeneralDL}
                                                value={elemento.valor}
                                                name={elemento.id}
                                            />
                                        </div>
                                    })
                                }
                            </div>

                            <div className="my-8 h-[1px] bg-gray-900 dark:bg-gray-500"></div>

                            <div>
                                <h2 className="underline">Dirección</h2>

                                <div className="mt-2 lg:grid lg:grid-cols-4 lg:gap-4">
                                    {
                                        metadatosDireccionesDL.map((elemento, index) => {
                                            if (elemento.nombre === "Acción") return null
                                            return <div key={index}>
                                                <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                <TextInput
                                                    id={elemento.id}
                                                    type={elemento.tipo}
                                                    onChange={manejadorDireccionDL}
                                                    value={elemento.valor}
                                                    name={elemento.id}
                                                />
                                            </div>
                                        })
                                    }
                                </div>
                            </div>

                            <div className="my-8 h-[1px] bg-gray-900 dark:bg-gray-500"></div>

                            <div>
                                <h2 className="underline">Contacto</h2>

                                <div className="mt-2 lg:grid lg:grid-cols-4 lg:gap-4">
                                    {
                                        metadatosContactoDL.map((elemento, index) => {
                                            if (elemento.nombre === "Acción") return null
                                            return <div key={index}>
                                                <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                <TextInput
                                                    id={elemento.id}
                                                    type={elemento.tipo}
                                                    onChange={manejadorContactoDL}
                                                    value={elemento.valor}
                                                    name={elemento.id}
                                                />
                                            </div>
                                        })
                                    }
                                </div>
                            </div>

                            <div className="mt-3">
                                <Button onClick={manejadorAgregarTrabajoDL}>Agregar</Button>
                            </div>
                        </div>

                        <div>
                            {
                                listaTrabajo.length > 0 && <div className="mt-6">
                                    <h4>Historial Laboral</h4>
                                    <div className="overflow-x-auto mt-2" >
                                        <table className="border-2 border-gray-900">
                                            <thead>
                                                <tr>
                                                    {
                                                        cabeceraHistorial.map((elemento, index) => {
                                                            return <th className="text-white bg-gray-900 py-4 border-r-2 border-gray-700 last:border-none" key={index}> {elemento.nombre} </th>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listaTrabajo.map((elemento, index) => {
                                                        return <tr className="dark:bg-gray-700 border-b-2 border-gray-900" key={index}>
                                                            <td className="border-r-2 border-gray-900" >{elemento.empresa}</td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.rubro} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.cargo} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.fecha_ing} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.fecha_egr} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.salario} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.departamentoDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.ciudadDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.barrioDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.calle_principalDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.calle_secundariaDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.nro_casaDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.emailDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.telefonoDL} </td>
                                                            <td className="border-r-2 border-gray-900" > {elemento.celularDL} </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>

                    <h3 className="mt-14">Referencias (mínimamente 3) </h3>
                    <form className="mt-2 mb-10 border-2 border-gray-300 border-opacity-50 p-3 rounded-md">
                        <div>
                            {/* <h3>Referencia</h3> */}
                            <div className="lg:grid lg:grid-cols-4 lg:gap-4">
                                {metadatosDR.map((elemento, index) => {
                                    if (elemento.id !== "rucDR")
                                        return (<div key={index}>
                                            <div>
                                                <Label htmlFor={elemento.id} value={elemento.nombre} />
                                            </div>
                                            <TextInput type={elemento.tipo} id={elemento.id} name={elemento.id} value={elemento.valor} onChange={manejadorDR} />
                                        </div>)

                                    else {
                                        return <div key={index} className="flex flex-col justify-center" >
                                            <Label className="ml-1" htmlFor={elemento.id} value={elemento.nombre} name={elemento.id} />
                                            <div className="flex gap-1 items-center">
                                                <TextInput type={elemento.tipo} id={elemento.id} value={elemento.valor} name={elemento.id} onChange={manejadorDR} />
                                                <span> - </span>
                                                <TextInput type="number" className="max-w-[80px]" name="dvDR" value={referencia.dvDR} onChange={manejadorDR} />
                                            </div>
                                        </div>
                                    }
                                })}
                            </div>
                        </div>

                        <div>
                            <Button onClick={manejadorAgregarDR} className="mt-3">Agregar</Button>
                        </div>

                        {
                            listaReferencia.length > 0 && (<div className="my-6">
                                <h4>Lista de referencias</h4>
                                <table className="border-2 border-gray-900 mt-2">
                                    <thead className="bg-gray-900">
                                        <tr>
                                            {metadatosDR.map((elemento, index) => {
                                                return <th className="border-r-2 last:border-none border-gray-700" key={index} >{elemento.nombre}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listaReferencia.map((elemento, index) => {
                                                return <tr className="bg-gray-700 border-b-2 border-gray-900" key={index}>
                                                    <td className="border-r-2 border-gray-900"> {elemento.rucDR}{" "} - {" "} {elemento.dvDR} </td>
                                                    <td className="border-r-2 border-gray-900"> {elemento.nombresDR}</td>
                                                    <td className="border-r-2 border-gray-900"> {elemento.apellidosDR}</td>
                                                    <td className="border-r-2 border-gray-900"> {elemento.parentescoDR}</td>
                                                    <td className="border-r-2 border-gray-900"> {elemento.telefonoDR}</td>
                                                    <td className="border-r-2 border-gray-900"> {elemento.celularDR}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>)
                        }
                    </form>
                </div>
            </div>

            <div className="flex justify-center items-center gap-2 mb-16">
                <Button className="p-2" >Guardar Solicitud</Button>
                <NavLink className="block p-3 rounded-lg border-2 border-gray-900 dark:border-white dark:hover:bg-white dark:hover:text-gray-900 transition-all " to="/ventas/detalle-solicitud" >Detalle Solicitud <FontAwesomeIcon icon={faArrowRight} /> </NavLink>
            </div>

        </div>
    )
}

export default NuevaSolicitud