
import {
    Outlet
} from 'react-router-dom';

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';

import {
    faCode,
} from '@fortawesome/free-solid-svg-icons';



function Informatica() {
    return (
        <main className="contenedor dark:bg-grisDark dark:text-white">
            <h1 className="titulo"> <FontAwesomeIcon icon={faCode} /> Informática</h1>
            
            <Outlet />
        </main>
    );
}

export default Informatica;