import { useState } from "react";

import Axios from "axios";
import { apiAutenticacion } from "../recursos/configuracion";

import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";
import { Button, Label, TextInput } from "flowbite-react";

function Acceso() {
  const navegar = useNavigate();

  // Se crea los estados para guardar usuario y contrasena
  const [usuario, setUsuario] = useState("");
  const [contrasena, setContrasena] = useState("");

  // Función que se encarga de llamar a la api para obtener el token si los campos no son vacíos
  async function ingresar(e) {
    e.preventDefault();
    if (usuario === "") {
      Swal.fire("Atención", "El campo usuario esta vacio", "info");
    } else if (contrasena === "") {
      Swal.fire("Atención", "El campo contraseña esta vacio", "info");
    } else {
      try {
        const parametros = {
          usuario,
          contrasena,
        };

        // Llama a la api para obtener el token
        let respuesta = await Axios.post(
          `${apiAutenticacion}/acceso`,
          parametros,
          {
            headers: {
              Authorization: `Bearer 0`,
            },
          }
        );

        /* Si está todo ok, guardamos el token en sessionStorage con el nombre de "authorization" y  
                navegamos a la página de inicio
                */
        if (respuesta.data.codigo === 1) {
          sessionStorage.setItem(
            "autorizacion",
            respuesta.headers["authorization"].split(" ")[1]
          );
          navegar("/inicio");
        } else {
          sessionStorage.removeItem("autorizacion");
        }
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: respuesta.data.descripcion,
        });
        //console.log(respuesta.data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  // const eventoEnter = (event) => {
  //   if (event.key === "Enter") {
  //     ingresar();
  //   }
  // };

  return (
    <>
      <div className="dark:bg-grisDark text-gray-700 dark:text-white flex items-center justify-center w-full min-h-[70vh]">
        <div className="flex border rounded-md border-gray-700 dark:border-white p-8 flex-col w-[90%] max-w-[450px] shadow-none">
          <h4 className="block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
            Iniciar Sesión
          </h4>
          <p className="mt-1 block font-sans text-base font-normal leading-relaxed antialiased">
            Complete los siguientes campos
          </p>
          <form onSubmit={ingresar} className="flex flex-col gap-4 mt-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="usuario" value="Usuario" />
              </div>
              <TextInput
                id="usuario"
                type="text"
                placeholder="Usuario"
                required
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="contrasena" value="Contraseña" />
              </div>
              <TextInput
                id="contrasena"
                type="password"
                placeholder="Contraseña"
                required
                onChange={(e) => setContrasena(e.target.value)}
              />
            </div>

            <Button className="bg-gray-800 dark:bg-azulLight" type="submit">
              Ingresar
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Acceso;
