import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ToggleSwitch } from "flowbite-react"
import { useModoOscuro, useSetModoOscuro } from "../proveedor/modoOscuro"

function ModoOscuro() {

    const modoOscuro = useModoOscuro()
    const setModoOscuro = useSetModoOscuro()

    function manejadorModoOscuro(e, modo) {
        if (modo === "inverso") {
            localStorage.setItem("theme", !modoOscuro ? "dark" : "light")
            if (modoOscuro) {
                document.documentElement.classList.remove('dark')
                window.matchMedia('(prefers-color-scheme: light)');
            } else {
                document.documentElement.classList.add('dark')
                window.matchMedia('(prefers-color-scheme: dark)');
            }
            setModoOscuro(!modoOscuro)
        } else if (modo === "oscuro") {
            document.documentElement.classList.add('dark')
            window.matchMedia('(prefers-color-scheme: dark)');
            localStorage.setItem("theme", "dark")
            setModoOscuro(true)
        } else if (modo === "claro") {
            document.documentElement.classList.remove('dark')
            window.matchMedia('(prefers-color-scheme: light)');
            localStorage.setItem("theme", "light")
            setModoOscuro(false)
        }
    }


    return (
        <div className="absolute top-0 right-0 mt-1 flex items-center gap-2 text-gray-900 dark:text-white lg:mt-6 lg:mr-6">
            <button onClick={(e) => manejadorModoOscuro(e, "claro") } >
                <FontAwesomeIcon icon={faSun} />
            </button>
            <ToggleSwitch onChange={ (e) => manejadorModoOscuro(e, "inverso") } checked={modoOscuro} />
            <button onClick={(e) => manejadorModoOscuro(e, "oscuro") }>
                <FontAwesomeIcon icon={faMoon} />
            </button>
        </div>
    )
}

export default ModoOscuro