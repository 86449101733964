const Axios = require('axios');
const moment = require('moment');

const {
    apiSyslog,
    apiAmbiente
} = require('./configuracion');

// Pasa el error a la api de Syslog
export async function reportar(usuario, programa, archivo, funcion, descripcion, detalle, tipo) {
    try {
        var parametros = {
            ambiente: apiAmbiente,
            usuario: usuario,
            programa: programa,
            lado: 'Backend',
            archivo: archivo,
            funcion: funcion,
            descripcion: descripcion,
            detalle: detalle,
            tipo: tipo
        }
        let respuesta = await Axios.post(`${apiSyslog}/registros`, parametros);
        console.log(respuesta.data);
    } catch (error) {
        console.log(error);
    }
}

/* Recibe como parámentros las interfaces habilitadas a su cuenta y la interfaz requirida, si la requerida
    existe dentro de las interfaces habilitadas devuelve true, si no existe devuelve false
*/
export function obtenerInterfaz(interfaces, interfazRequerida) {
    return (interfaces.findIndex((interfaz) => interfaz.nombre === interfazRequerida) >= 0 ? true : false);
}


export function obtenerPermiso(interfaces, interfazRequerida, permisoRequerido) {
    let envio = false;
    let posicionInterfaz = interfaces.findIndex((interfaz) => interfaz.nombre === interfazRequerida);
    if (posicionInterfaz >= 0) {
        envio = (interfaces[posicionInterfaz].permiso.findIndex((permiso) => permiso === permisoRequerido) >= 0 ? true : false);
    } else {
        envio = false;
    }
    return envio;
}

// Obtiene del token los datos necesarios de la cuenta (aún sin descifrar), lo descifra y lo retorna 
export function obtenerCuenta(token) {
    const cargaUtil = token.split('.')[1];
    const descifrado = JSON.parse(atob(cargaUtil));
    return descifrado;
}

export function obtenerFechaDeTiempo(tiempo) {
    let envio = moment(tiempo).format("DD-MM-YYYY");
    return envio
}

export function obtenerHoraDeTiempo(tiempo) {
    let envio = moment(tiempo).format("HH:mm:ss");
    return envio
}

export function obtenerFechaHoy() {
    let fechaHoy = new Date();
    let fechaHoyFormateado =  `${fechaHoy.getFullYear()}-${fechaHoy.getMonth() + 1}-${fechaHoy.getDate()}` 
    return fechaHoyFormateado
}

export function separadorDeMiles(entero) {
    return parseInt(entero).toLocaleString('es-PY');
}