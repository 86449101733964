
import { Outlet } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMagnifyingGlassDollar } from "@fortawesome/free-solid-svg-icons";

function Referencias() {
  return (
    <main className="contenedor dark:bg-grisDark">
      <h1 className="titulo text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faMagnifyingGlassDollar} /> Referencias
      </h1>
      <Outlet />
    </main>
  );
}

export default Referencias;
