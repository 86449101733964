import { useState } from "react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Checkbox, Label, Radio, Select, TextInput } from "flowbite-react"

function DetalleSolicitud() {

    const valorInicialBuscador = {
        codigo: "",
        stock: 2,
        nombre: "",
        otrosDispositivos: "N/A",
        cantidad: 0,
        precioUnitario: 10000000,
        subTotal: 10000000,
        detalle: "N/A"
    }

    const valorInicialSolicitud = {
        vendedor: "1",
        nombreVendedor: "General",
        numero: "000-000-000",
        secuencia: "0",
        fecha: "27/11/2023",
        agendarFuturo: "27/11/2023",
        enviarAnalista: false,
        motivo: ""
    }

    const valorInicialFormaPago = {
        condicion: "",
        cuota: 0,
        entregaInicial: false,
        vencimiento: "",
        metodoPago: "",
        total: "",
    }

    const [buscador, setBuscador] = useState(valorInicialBuscador)
    const [solicitud, setSolicitud] = useState(valorInicialSolicitud)
    const [formaPago, setFormaPago] = useState(valorInicialFormaPago)

    const metadatosBuscador = [
        { nombre: "Código", id: "codigo", valor: buscador.codigo, tipo: "text" },
        { nombre: "Cantidad", id: "cantidad", valor: buscador.cantidad, tipo: "number" },
        { nombre: "Detalle", id: "detalle", valor: buscador.detalle, tipo: "text" },
        { nombre: "Stock", id: "stock", valor: buscador.stock, tipo: "number" },
        { nombre: "Otros dispositivos", id: "otrosDispositivos", valor: buscador.otrosDispositivos, tipo: "text" },
        { nombre: "Precio Unitario", id: "precioUnitario", valor: buscador.precioUnitario, tipo: "text" },
        { nombre: "Subtotal", id: "subTotal", valor: buscador.subTotal, tipo: "text" },
    ]

    const metadatosSolicitud = [
        { nombre: "Vendedor", id: "vendedor", valor: solicitud.vendedor, tipo: "text" },
        { nombre: "Nombre Vendedor", id: "nombreVendedor", valor: solicitud.nombreVendedor, tipo: "text" },
        { nombre: "Número", id: "numero", valor: solicitud.numero, tipo: "number" },
        { nombre: "Secuencia", id: "secuencia", valor: solicitud.secuencia, tipo: "text" },
        { nombre: "Fecha", id: "fecha", valor: solicitud.fecha, tipo: "date" },
        { nombre: "Agendar Futuro", id: "agendarFuturo", valor: solicitud.agendarFuturo },
        { nombre: "Enviar Analista", id: "enviarAnalista", valor: solicitud.enviarAnalista, tipo: "Checkbox" },
        { nombre: "Motivo", id: "motivo", valor: solicitud.motivo, tipo: "text" }
    ]

    const metadatosFormaPago = [
        { nombre: "Condición", id: "condicion", valor: formaPago.condicion },
        { nombre: "Cuota", id: "cuota", valor: formaPago.cuota, tipo: "number" },
        { nombre: "Entrega inicial", id: "entregaInicial", valor: formaPago.entregaInicial },
        { nombre: "Vencimiento", id: "vencimiento", valor: formaPago.vencimiento, tipo: "date" },
        { nombre: "Metodo de Pago", id: "metodoPago", valor: formaPago.metodoPago },
        { nombre: "Total", id: "total", valor: formaPago.total, tipo: "number" }
    ]

    function manejadorBuscador(e) {

    }

    function manejadorSolicitud(e) {
        const { name, value, checked } = e.target
        if (name === "enviarAnalista") {
            setSolicitud(prev => ({ ...prev, [name]: checked }))
        }
        else {
            setSolicitud(prev => ({ ...prev, [name]: value }))
        }
    }

    function manejadorFormaPago(e) {
        const { name, value, checked } = e.target
        if (name === "entregaInicial") {
            setFormaPago(previo => ({ ...previo, [name]: checked }))
        } else {
            setFormaPago(prev => ({ ...prev, [name]: value }))
        }
    }

    const number = 5
    const number2 = 8
    const [array, setArray] = useState(Array(number).fill("h"))
    const [array2, setArray2] = useState(Array(number2).fill("h"))


    return (
        <div className="dark:text-white mt-6">
            <h2 className="sub-titulo">Detalle Solicitud</h2>
            <div className="mt-4">
                <form>
                    <div className="lg:grid lg:grid-cols-7 lg:gap-10">
                        <div className="lg:col-span-4">
                            <div className="lg:grid lg:grid-cols-6 lg:gap-2">
                                {
                                    metadatosBuscador.map((elemento, index) => {
                                        if (elemento.id === "codigo") {
                                            return <div className="flex items-end gap-1 col-span-3 " key={index}>
                                                <div>
                                                    <div>
                                                        <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                    </div>

                                                    <TextInput className="max-w-[120px]" id={elemento.id} onChange={manejadorBuscador} value={elemento.valor} />
                                                </div>

                                                <div className="p-2 bg-gray-700 rounded-md w-full">SAMSUNG GALAXY S22 PLUS </div>
                                            </div>
                                        } else if (elemento.id === "cantidad") {
                                            return <div key={index} >
                                                <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                <TextInput id={elemento.id} value={elemento.valor} onChange={manejadorBuscador} />
                                            </div>
                                        } else {
                                            return (<div key={index}>
                                                <div>
                                                    <Label value={elemento.nombre} />
                                                </div>

                                                <div className="lg:bg-gray-700 lg:p-2 rounded-md">
                                                    <span>{elemento.valor}</span>
                                                </div>
                                            </div>)
                                        }
                                    })
                                }
                                <div className="mt-4 flex gap-2">
                                    <Button>Aceptar</Button>
                                    <Button className="bg-red-500 dark:bg-red-500 hover:bg-red-600">Cancelar</Button>
                                </div>
                            </div>

                            <div className="mt-6 pt-4 border-t border-gray-900 dark:border-white lg:grid lg:grid-cols-2 lg:gap-3">
                                {
                                    metadatosFormaPago.map((elemento, index) => {
                                        if (elemento.id === "condicion") {
                                            return <div key={index}>
                                                <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                <Select id={elemento.id} name="condicion" >
                                                    <option value="contado">Contado</option>
                                                    <option value="credito">Crédito</option>
                                                </Select>
                                            </div>
                                        } else if (elemento.id === "entregaInicial") {
                                            return <div key={index}>
                                                <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                <span className="ml-1" >
                                                    <Checkbox id={elemento.id} name={elemento.id} checked={elemento.valor} onChange={manejadorFormaPago} />
                                                </span>
                                                <TextInput disabled={elemento.valor ? true : false} />
                                            </div>
                                        } else if (elemento.id === "metodoPago") {
                                            return <div key={index}>
                                                <p>Forma de Pago</p>
                                                <div className="flex gap-2">
                                                    <div>
                                                        <Radio id="mensual" name="metodoPago" />
                                                        <Label htmlFor="mensual" value="Mensual" />
                                                    </div>
                                                    <div>
                                                        <Radio id="quincenal" name="metodoPago" />
                                                        <Label htmlFor="quincenal" value="Quincenal" />
                                                    </div>
                                                    <div>
                                                        <Radio id="semanal" name="metodoPago" />
                                                        <Label htmlFor="semanal" value="Semanal" />
                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            return <div key={index}>
                                                <Label htmlFor={elemento.id} value={elemento.nombre} />
                                                <TextInput className={`${elemento.id === "cuota" ? "max-w-[70px]" : ""}`} type={elemento.tipo} name={elemento.id} value={elemento.valor} onChange={manejadorFormaPago} />
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>

                        <div className="lg:col-span-3" >
                        <table className="border-2 border-gray-900" >
                            <thead className="bg-gray-900" >
                                <tr>
                                    <th className="border-r-2 border-gray-700">Cuota</th>
                                    <th className="border-r-2 border-gray-700">Importe</th>
                                    <th>Vence</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    array2.map( (elemento, index) => {
                                        return <tr key={index} className="border-b-2 border-gray-900 bg-gray-700" >
                                            <td className="border-r-2 border-gray-900" >.</td>
                                            <td className="border-r-2 border-gray-900" >.</td>
                                            <td className="border-r-2 border-gray-900" >.</td>
                                        </tr>
                                    } )
                                }
                            </tbody>
                        </table>
                    </div>

                    </div>

                    

                </form>
            </div>

            <div>
                <form>
                    <div className="lg:grid lg:grid-cols-3 lg:gap-2">
                        {/* <div className="border-r pr-3 border-gray-900 dark:lg:border-white lg:grid lg:grid-cols-2 lg:gap-4">
                            {
                                metadatosSolicitud.map((elemento, index) => {
                                    if (elemento.id !== "motivo" && elemento.id !== "enviarAnalista") {
                                        return <div className="mb-2" key={index}>
                                            <span> {elemento.nombre}: </span>
                                            <span className="p-3 bg-gray-700 rounded-md"> {elemento.valor} </span>
                                        </div>
                                    } else if (elemento.id === "enviarAnalista") {
                                        return <div key={index} >
                                            <label htmlFor={elemento.id} value={elemento.nombre + ": "}>Enviar analista: </label>
                                            <Checkbox id={elemento.id} name={elemento.id} checked={elemento.valor} onChange={manejadorSolicitud} />
                                        </div>
                                    }

                                    else if (elemento.id === "motivo") {
                                        return <div className="mb-2" key={index}>
                                            <Label htmlFor={elemento.id} value={elemento.nombre} />
                                            <TextInput type={elemento.tipo} name={elemento.id} id={elemento.id} value={elemento.valor} onChange={manejadorSolicitud} />
                                        </div>
                                    }
                                })
                            }
                        </div> */}

                    </div>
                </form>
            </div>

            <div className="mt-12 mb-12 overflow-y-auto">
                <table className="border-2 border-gray-900">
                    <thead className="bg-gray-900">
                        <tr>
                            <th className="border-r-2 border-gray-700" >Código</th>
                            <th className="border-r-2 border-gray-700" >Cantidad</th>
                            <th className="border-r-2 border-gray-700" >Clase de Mercaderías y/o Servicios</th>
                            <th className="border-r-2 border-gray-700" >Precio</th>
                            <th className="border-r-2 border-gray-700" >Exenta</th>
                            <th className="border-r-2 border-gray-700" >Gravadas 5%</th>
                            <th>Gravadas 10%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            array.map((elemento, index) => {
                                return <tr className="dark:bg-gray-700 border-b-2 border-gray-900" key={index} >
                                    <td className="border-r-2 border-gray-900" >&nbsp;</td>
                                    <td className="border-r-2 border-gray-900" >&nbsp;</td>
                                    <td className="border-r-2 border-gray-900" >&nbsp;</td>
                                    <td className="border-r-2 border-gray-900" >&nbsp;</td>
                                    <td className="border-r-2 border-gray-900" >&nbsp;</td>
                                    <td className="border-r-2 border-gray-900" >&nbsp;</td>
                                    <td className="border-r-2 border-gray-900" >&nbsp;</td>
                                </tr>
                            })
                        }

                        <tr className="dark:bg-gray-700 border-b-2 border-t-4 border-gray-900">
                            <td colSpan={5} className="border-r-2 border-gray-900">Sub-Totales</td>
                            <td className="border-r-2 border-gray-900">0.00</td>
                            <td>0.00</td>
                        </tr>

                        <tr className="dark:bg-gray-700 border-b-2 border-gray-900">
                            <td colSpan={5} className="border-r-2 border-gray-900">Descuentos</td>
                            <td className="border-r-2 border-gray-900">0.00</td>
                            <td>0.00</td>
                        </tr>

                        <tr className="dark:bg-gray-700 border-b-2 border-gray-900">
                            <td colSpan={5} className="border-r-2 border-gray-900">Liquidación del IVA</td>
                            <td className="border-r-2 border-gray-900">0.00</td>
                            <td>0.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default DetalleSolicitud