
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import './estilos/General.css';

import {
  CuentaProveedor
} from './proveedor/cuenta';

import Navegacion from './componentes/Navegacion';
import Footer from './componentes/Footer';

import Acceso from './paginas/acceso';
import Inicio from './paginas/inicio';
import Desarrollo from './paginas/desarrollo';

import Referencias from './paginas/referencias';
import ReferenciasConsulta from './paginas/referencias/consulta';

import Recursos_humanos from './paginas/recursos_humanos';
import Recursos_humanosSalarios from './paginas/recursos_humanos/salarios';

import Ventas from './paginas/Ventas';
import NuevaSolicitud from './paginas/ventas/NuevaSolicitud';
import Solicitudes from './paginas/ventas/Solicitudes';
import DetalleSolicitud from './paginas/ventas/DetalleSolicitud';

import Informatica from './paginas/informatica';
import Procesos from './paginas/informatica/procesos';
import Syslog from './paginas/informatica/syslog';
import Personas from './paginas/informatica/Personas';
import Persona from './paginas/informatica/Persona';
import { useEffect } from 'react';
import ModoOscuro from './componentes/ModoOscuro';
import ModoOscuroProveedor from './proveedor/modoOscuro';


function App() {

  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (localStorage.getItem("theme") === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      console.log("dark")
      document.documentElement.classList.add('dark')
      window.matchMedia('(prefers-color-scheme: dark)');
    } else {
      console.log("light")
      window.matchMedia('(prefers-color-scheme: light)');
      document.documentElement.classList.remove('dark')
    }
  }, [])

  return (
    <BrowserRouter>
      <CuentaProveedor>
        <ModoOscuroProveedor>
          <Navegacion />
          <ModoOscuro />

          <Routes>
            <Route path='/' element={<Acceso />} />
            <Route path='/inicio' element={<Inicio />} />
            <Route path='/referencias' element={<Referencias />} >
              <Route path='consulta' element={<ReferenciasConsulta />} />
            </Route>
            <Route path='/cobranzas' element={<Desarrollo />} />
            <Route path='/tesoreria' element={<Desarrollo />} />
            <Route path='/recursos_humanos' element={<Recursos_humanos />} >
              <Route path='salarios' element={<Recursos_humanosSalarios />} />
            </Route>
            <Route path='/informatica' element={<Informatica />} >
              <Route path='procesos' element={<Procesos />} />
              <Route path='syslog' element={<Syslog />} />
              <Route path='personas' element={<Personas />} />
              <Route path='personas/:id' element={<Persona />} />
            </Route>
            <Route path='/marketing' element={<Desarrollo />} />
            <Route path='/soporte_tecnico' element={<Desarrollo />} />
            <Route path='/compras' element={<Desarrollo />} />

            <Route path='/ventas' element={<Ventas />} >
              <Route path='solicitudes' element={<Solicitudes />} />
              <Route path='nueva-solicitud' element={<NuevaSolicitud />} />
              <Route path='detalle-solicitud' element={<DetalleSolicitud /> } />
            </Route>
          </Routes>
          {/* <Footer /> */}
        </ModoOscuroProveedor>
      </CuentaProveedor>
    </BrowserRouter>
  );
}

export default App;