import { useState, useEffect } from "react";

import { Table, Button } from "flowbite-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faMoneyBill,
  faTrash,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";

import { apiProcesos } from "../../recursos/configuracion";

import { separadorDeMiles, obtenerFechaDeTiempo } from "../../recursos/funciones";
import Loader from "../../componentes/Loader";

function Salarios() {

  // Estado que tendrá la lista de salarios posteriormente
  const [datos, setDatos] = useState([]);

  const cabecerasDeTabla = [
    "Documento",
    "Empleado",
    "Cuenta",
    "Monto",
    "Fecha",
    "Acción",
  ];

  const [carga, setCarga] = useState(true);

  // Se ejecuta después del primer renderizado
  useEffect(() => {
    cargarTabla();
  }, []);

  // Trae la lista de salarios
  async function cargarTabla() {
    try {
      let resultado = await Axios.get(`${apiProcesos}/salarios`);
      setDatos([]);
      console.log(resultado.data.lista);
      setDatos(resultado.data.lista);
    } catch (error) {
      console.log(error);
    }
    setCarga(false);
  }

  async function quitarLista(indice) {
    const nuevaLista = datos.filter((elemento, llave) => llave !== indice);
    setDatos(nuevaLista);
  }

  function generarDatos() {
    let conjunto = "";
    let total = 0;
    let linea = "";

    let formato = {
      // 252
      ITIREG: "", // 1
      ITITRA: "", // 2
      ICDSRV: 0, // 3
      ICTDEB: 0, // 10
      IBCOCR: 0, // 3
      ICTCRE: 0, // 10
      ITCRDB: "", // 1
      IORDEN: "", // 50
      IMONED: 0, // 1
      IMTOTR: 0, // 15
      IMTOT2: 0, // 15
      INRODO: "", // 12
      ITIFAC: 0, // 1
      INRFAC: "", // 20
      INRCUO: 0, // 3
      IFCHCR: 0, // 8
      IFCHC2: 0, // 8
      ICEPTO: "", // 50
      INRREF: "", // 15
      IFECCA: 0, // 8
      IHORCA: 0, // 6
      IUSUCA: "", // 10
    };

    try {
      for (const dato of datos) {
        total = total + parseInt(dato.monto_transferencia);
        formato.ITIREG = dato.Tipo_de_registro_enviado; // 1
        formato.ITITRA = dato.Tipo_Transferencia; // 2
        formato.ICDSRV = dato.codigo_empresa; // 3
        formato.ICTDEB = dato.nro_cuenta_debito.toString().padStart(10, "0"); // 10
        formato.IBCOCR = dato.nro_banco_para_credito.toString(); // 3
        formato.ICTCRE = dato.nro_cuenta_credito.toString().padStart(10, "0"); // 10
        formato.ITCRDB = dato.tipo; // 1
        formato.IORDEN = dato.cliente.padEnd(50, " "); // 50
        formato.IMONED = dato.moneda.toString(); // 1
        formato.IMTOTR = parseInt(dato.monto_transferencia)
          .toFixed(2)
          .replace(".", "")
          .padStart(15, "0"); // 15
        formato.IMTOT2 = dato.monto_transferencia_segundo
          .toString()
          .padStart(15, "0"); // 15
        formato.INRODO = dato.nro_documento.toString().padEnd(12, " "); // 12
        formato.ITIFAC = dato.tipo_factura.toString(); // 1
        formato.INRFAC = dato.nro_factura.padEnd(20, " "); // 20
        formato.INRCUO = dato.nro_cuota.toString().padStart(3, "0"); // 3
        formato.IFCHCR = dato.fecha_credito.toString().padStart(8, "0"); // 8
        formato.IFCHC2 = dato.fecha_segundo_vencimiento
          .toString()
          .padStart(8, "0"); // 8
        formato.ICEPTO = dato.concepto.padEnd(50, " "); // 50
        formato.INRREF = dato.referencia_operacion_empresa.padEnd(15, " "); // 15
        formato.IFECCA = dato.fecha_carga_transaccion
          .toString()
          .padStart(8, "0"); // 8
        formato.IHORCA = dato.hora_carga_transaccion
          .toString()
          .padStart(6, "0"); // 6
        formato.IUSUCA = dato.nombre_usuario.padEnd(10, " "); // 10

        linea = `${formato.ITIREG}${formato.ITITRA}${formato.ICDSRV}${formato.ICTDEB}${formato.IBCOCR}${formato.ICTCRE}${formato.ITCRDB}${formato.IORDEN}${formato.IMONED}${formato.IMTOTR}${formato.IMTOT2}${formato.INRODO}${formato.ITIFAC}${formato.INRFAC}${formato.INRCUO}${formato.IFCHCR}${formato.IFCHC2}${formato.ICEPTO}${formato.INRREF}${formato.IFECCA}${formato.IHORCA}${formato.IUSUCA}`;
        conjunto = `${conjunto}${linea}\n`;
      }

      /*console.log(total.toFixed(2).replace('.', '').padStart(15, '0'));
            console.log(total.toFixed(2).replace('.', ''));
            console.log(total.toFixed(2));
            console.log(total);*/

      formato.ITIREG = "C"; // 1
      formato.ITITRA = "".padEnd(2, " "); // 2
      formato.ICDSRV = "0".padStart(3, "0"); // 3
      //formato.ICTDEB = 0    // 10
      formato.IBCOCR = "0".padStart(3, "0"); // 3
      formato.ICTCRE = "0".padStart(10, "0"); // 10
      formato.ITCRDB = "".padEnd(1, " "); // 1
      formato.IORDEN = "".padEnd(50, " "); // 50
      formato.IMONED = "0".padStart(1, "0"); // 1
      formato.IMTOTR = total.toFixed(2).replace(".", "").padStart(15, "0"); // 15
      formato.IMTOT2 = "0".padStart(15, "0"); // 15
      formato.INRODO = "".padEnd(12, " "); // 12
      formato.ITIFAC = "0".padStart(1, "0"); // 1
      formato.INRFAC = "".padEnd(20, " "); // 20
      formato.INRCUO = "0".padStart(3, "0"); // 3
      formato.IFCHCR = "0".padStart(8, "0"); // 8
      formato.IFCHC2 = "0".padStart(8, "0"); // 8
      formato.ICEPTO = "".padEnd(50, " "); // 50
      formato.INRREF = "".padEnd(15, " "); // 15
      formato.IFECCA = "0".padStart(8, "0"); // 8
      formato.IHORCA = "0".padStart(6, "0"); // 6
      formato.IUSUCA = "".padEnd(10, " "); // 10

      linea = `${formato.ITIREG}${formato.ITITRA}${formato.ICDSRV}${formato.ICTDEB}${formato.IBCOCR}${formato.ICTCRE}${formato.ITCRDB}${formato.IORDEN}${formato.IMONED}${formato.IMTOTR}${formato.IMTOT2}${formato.INRODO}${formato.ITIFAC}${formato.INRFAC}${formato.INRCUO}${formato.IFCHCR}${formato.IFCHC2}${formato.ICEPTO}${formato.INRREF}${formato.IFECCA}${formato.IHORCA}${formato.IUSUCA}`;
      conjunto = `${conjunto}${linea}`;
    } catch (error) {
      console.log(error);
    }
    return conjunto;
  }

  function generarTXT() {
    generarDatos();
    // console.log(generarDatos());
    const contenido = generarDatos().replace(/Ñ/g, 'N');
        const blob = new Blob([contenido], { type: 'text/plain;charset=utf-8' });
        const enlace = document.createElement('a');
        enlace.href = window.URL.createObjectURL(blob);
        enlace.download = 'TRANSFER.TXT';
        enlace.click();
        window.URL.revokeObjectURL(enlace.href);
  }

  return (
    <div className="flex flex-col gap-8">
      <div>
        <h2 className="sub-titulo text-gray-900 dark:text-white ">
          <FontAwesomeIcon icon={faMoneyBill} /> Salarios (Itaú)
        </h2>
      </div>

      <div>
        <Button className="bg-gray-900" onClick={generarTXT}>
          <FontAwesomeIcon icon={faFileArrowDown} /> <span className="ml-2" >Generar TXT (Itaú)</span> 
        </Button>
      </div>

      {carga ? <Loader /> :
        <div className="overflow-x-auto" >
          <table className="border-2 border-gray-900">
            <thead className="font-bold lg:text-lg">
              <tr>
                {cabecerasDeTabla.map((elemento) => {
                  return (
                    <th key={elemento} className="bg-gray-900 py-4 text-white">
                      {elemento}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {datos.map((dato, llave) => {
                return (
                  <tr
                    key={llave}
                    className="bg-white dark:bg-gray-700 border-t-0 border-b border-gray-900"
                  >
                    <td className="text-gray-900 dark:text-white lg:text-lg py-2">
                      {separadorDeMiles(dato.nro_documento)}
                    </td>
                    <td className="text-gray-900 dark:text-white lg:text-lg py-2">
                      {" "}
                      {dato.cliente}{" "}
                    </td>
                    <td className="text-gray-900 dark:text-white lg:text-lg py-2">
                      {" "}
                      {separadorDeMiles(dato.nro_cuenta_credito)}{" "}
                    </td>
                    <td className="text-gray-900 dark:text-white lg:text-lg py-2">
                      {" "}
                      {separadorDeMiles(dato.monto_transferencia)}{" "}
                    </td>
                    <td className="text-gray-900 dark:text-white lg:text-lg py-2">
                      {" "}
                      {obtenerFechaDeTiempo(dato.fecha_credito)}{" "}
                    </td>
                    <td className="text-gray-900 dark:text-white lg:text-lg py-2">
                      <Button
                        className="bg-red-500 dark:bg-red-500"
                        onClick={async () => {
                          await quitarLista(llave);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} /> <span className="ml-1">{llave + 1}</span> 
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> </div>}
    </div>
  );
}

export default Salarios;
